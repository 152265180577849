const state = {
  twoYearsTrainingData: "",
  threeYearsModules: [
    {
      name: "Orientierungseinsatz beim TpA",
      totalHours: 400,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Stationäre Akutpflege",
      totalHours: 400,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Stationäre Langzeitpflege",
      totalHours: 400,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Ambulante Akut-/Langzeitpflege",
      totalHours: 400,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Pädiatrische Versorgung",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Psychiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",

        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Vertiefungseinsatz im Bereich eines Pflichteinsatzes beim TpA",
      totalHours: 500,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 1",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 2",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Urlaub",
      totalHours: 0,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      vacationDays: [],
      trainingYear: 0,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
  ],
  twoYearsApModules: [
    {
      name: "Pflichteinsatz Stationäre Akutpflege",
      totalHours: 200,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Stationäre Langzeitpflege",
      totalHours: 270,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Ambulante Akut-/Langzeitpflege",
      totalHours: 270,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Pädiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Psychiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",

        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Vertiefungseinsatz im Bereich eines Pflichteinsatzes",
      totalHours: 500,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 1",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 2",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Urlaub",
      totalHours: 0,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      vacationDays: [],
      trainingYear: 0,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
  ],
  twoYearsLzpModules: [
    {
      name: "Pflichteinsatz Stationäre Akutpflege",
      totalHours: 300,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Stationäre Langzeitpflege",
      totalHours: 200,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Ambulante Akut-/Langzeitpflege",
      totalHours: 240,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Pädiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Psychiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",

        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Vertiefungseinsatz im Bereich eines Pflichteinsatzes",
      totalHours: 500,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 1",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 2",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Urlaub",
      totalHours: 0,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      vacationDays: [],
      trainingYear: 0,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
  ],
  twoYearsApLpModules: [
    {
      name: "Pflichteinsatz Stationäre Akutpflege",
      totalHours: 300,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Stationäre Langzeitpflege",
      totalHours: 240,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Ambulante Akut-/Langzeitpflege",
      totalHours: 200,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Pädiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 1,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Pflichteinsatz Psychiatrische Versorgung",
      totalHours: 120,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",

        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Vertiefungseinsatz im Bereich eines Pflichteinsatzes",
      totalHours: 500,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 1",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Wahleinsatz 2",
      totalHours: 80,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      trainingYear: 2,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
    {
      name: "Urlaub",
      totalHours: 0,
      plannedHours: 0,
      dayShiftRanges: [],
      nightShiftRanges: [],
      vacationDays: [],
      trainingYear: 0,
      trainingCompany: {
        name: "",
        contactPerson: "",
        contactPersonGender: "",
        street: "",
        houseNumber: "",
        postCode: "",
        place: "",
        telephone: "",
        email: "",
      },
    },
  ],
  currentModule: {
    name: "Orientierungseinsatz beim TpA",
    totalHours: 400,
    plannedHours: 0,
    dayShiftRanges: [],
    nightShiftRanges: [],
    trainingYear: 1,
    trainingCompany: {
      name: "",
      contactPerson: "",
      contactPersonGender: "",
      street: "",
      houseNumber: "",
      postCode: "",
      place: "",
      telephone: "",
      email: "",
    },
  },
  companyData: {
    name: "",
    contactPerson: "",
    contactPersonGender: "",
    street: "",
    houseNumber: "",
    postCode: "",
    place: "",
    telephone: "",
    email: "",
  },
  studentData: {
    lastname: "",
    firstname: "",
    class: "",
  },
  currentTrainingModules: [],
  currentSavedPlans: [],
  otherDurationSavedPlans: [],
  newPlanItem: { value: "Neuer Plan", name: "Neuer Plan" },
  savedPlansNames: [],
  currentSavedPlanName: null,
  numberOfPlannedModules: 0,
};
const getters = {
  getCurrentTrainingModules: (state) => state.currentTrainingModules,
  getCurrentModule: (state) => state.currentModule,
  getCompanyData: (state) => state.companyData,
  getStudentData: (state) => state.studentData,
  getCurrentSavedPlans: (state) => state.currentSavedPlans,
  getOtherDurationSavedPlans: (state) => state.otherDurationSavedPlans,
  getSavedPlansNames: (state) => state.savedPlansNames,
  getCurrentSavedPlanName: (state) => state.currentSavedPlanName,
  getNumberOfPlannedModules: (state) => state.numberOfPlannedModules,
};
const actions = {
  checkLocalStorage({ state }, duration) {
    state.savedPlansNames = [];
    state.currentSavedPlans = [];
    state.otherDurationSavedPlans = [];
    state.savedPlansNames.push(state.newPlanItem);
    var localStoragePlans = [],
      keys = Object.keys(localStorage),
      i = keys.length;

    while (i--) {
      try {
        localStoragePlans.push(JSON.parse(localStorage.getItem(keys[i])));
      } catch (e) {
        // console.log(e);
      }
    }
    localStoragePlans.forEach((plan) => {
      if (plan.trainingDuration === duration) {
        //TODO For the new release. Check if there is no vacation Module
        // To delete in the future
        if (
          !plan.modules.find(
            (trainingModule) => trainingModule.name === "Urlaub"
          )
        ) {
          plan.modules.push({
            name: "Urlaub",
            totalHours: 0,
            plannedHours: 0,
            dayShiftRanges: [],
            nightShiftRanges: [],
            vacationDays: [],
            trainingYear: 0,
            trainingCompany: {
              name: "",
              contactPerson: "",
              contactPersonGender: "",
              street: "",
              houseNumber: "",
              postCode: "",
              place: "",
              telephone: "",
              email: "",
            },
          });
        }
        state.currentSavedPlans.push(plan);
        state.savedPlansNames.push({
          value: `${plan.student.firstname} ${plan.student.lastname}`,
          name: `${plan.student.firstname} ${plan.student.lastname}`,
        });
      } else {
        // 6 is the number of the version Checking if plan is 6 because
        // I am saving everything in the localstorage as plans either
        //as current which has the same duration
        // or other for the other duration (2 or 3 years)
        if (plan !== 6) {
          //TODO For the new release. Check if there is no vacation Module
          // To delete in the future
          if (
            !plan.modules.find(
              (trainingModule) => trainingModule.name === "Urlaub"
            )
          ) {
            plan.modules.push({
              name: "Urlaub",
              totalHours: 0,
              plannedHours: 0,
              dayShiftRanges: [],
              nightShiftRanges: [],
              vacationDays: [],
              trainingYear: 0,
              trainingCompany: {
                name: "",
                contactPerson: "",
                contactPersonGender: "",
                street: "",
                houseNumber: "",
                postCode: "",
                place: "",
                telephone: "",
                email: "",
              },
            });
          }
          state.otherDurationSavedPlans.push(plan);
        }
      }
    });
    state.currentSavedPlanName = state.savedPlansNames[0];
  },
};

const mutations = {
  setCurrentModule(state, index) {
    state.currentModule = JSON.parse(
      JSON.stringify(state.currentTrainingModules[index])
    );
  },

  setCompanyData(state, data) {
    if (data === null) {
      state.companyData.name = "";
      state.companyData.contactPerson = "";
      state.companyData.contactPersonGender = "";
      state.companyData.street = "";
      state.companyData.houseNumber = "";
      state.companyData.postCode = "";
      state.companyData.place = "";
      state.companyData.telephone = "";
      state.companyData.email = "";
    } else {
      state.companyData = data;
    }
  },
  setStudentData(state, data) {
    if (data === null) {
      state.studentData.lastname = "";
      state.studentData.firstname = "";
      state.studentData.class = "";
    } else {
      state.studentData = data;
    }
  },

  updateStateModule(state, payload) {
    const index = state.currentTrainingModules.findIndex(
      (element) => element.name === payload.name
    );

    state.currentTrainingModules[index] = payload;
  },

  setCurrentSavedPlanName(state, payload) {
    state.currentSavedPlanName = payload;
  },

  resetAllModulesPlannedHoures(state) {
    state.currentTrainingModules.forEach(async (trainingModule) => {
      trainingModule.dayShiftRanges = [];
      trainingModule.nightShiftRanges = [];
      trainingModule.plannedHours = 0;
      if (trainingModule.vacationDays !== undefined) {
        trainingModule.plannedHours = 1;
        trainingModule.vacationDays = [];
      }
    });
  },

  setCurrentTrainingModules(state, payload) {
    if (payload.trainingDuration === 3) {
      state.currentTrainingModules = JSON.parse(
        JSON.stringify(state.threeYearsModules)
      );
    } else {
      if (payload.item === "GPA AP") {
        state.currentTrainingModules = JSON.parse(
          JSON.stringify(state.twoYearsApModules)
        );
      } else if (payload.item === "GPA LZP") {
        state.currentTrainingModules = JSON.parse(
          JSON.stringify(state.twoYearsLzpModules)
        );
      } else {
        state.currentTrainingModules = JSON.parse(
          JSON.stringify(state.twoYearsApLpModules)
        );
      }
    }
    state.currentModule = JSON.parse(
      JSON.stringify(state.currentTrainingModules[0])
    );
  },
  setCurrentTrainingModulesFromSavedPlan(state, payload) {
    state.currentTrainingModules = [...payload.trainingModules];
  },

  removeSavedPlan(state, planToRemove) {
    localStorage.removeItem(
      `${planToRemove.firstname} ${planToRemove.lastname}`
    );

    if (state.currentSavedPlans.indexOf(planToRemove) > -1) {
      state.currentSavedPlans.splice(
        state.otherDurationSavedPlans.indexOf(planToRemove, 1)
      );
    }

    if (state.otherDurationSavedPlans.indexOf(planToRemove) > -1) {
      state.otherDurationSavedPlans.splice(
        state.otherDurationSavedPlans.indexOf(planToRemove, 1)
      );
    }

    let foundStudentToDelete = state.savedPlansNames.find((student) => {
      student.name ===
        `${planToRemove.student.firstname} ${planToRemove.student.lastname}`;
    });

    if (state.savedPlansNames.indexOf(foundStudentToDelete, 1) !== -1) {
      state.savedPlansNames.splice(
        state.savedPlansNames.indexOf(foundStudentToDelete, 1)
      );
    }
  },

  setNumberOfPlannedModules(state, number) {
    state.numberOfPlannedModules = number;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
