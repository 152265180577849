<template>
  <div class="mb-4">
    <v-row no-gutters class="mt-0">
      <v-col cols="12">
        <!-- Planed days hint for vacation -->

        <div class="d-flex justify-space-between">
          <div
            v-if="currentModule.name === 'Urlaub'"
            class="modul-rest-totalHours pl-6"
          >
            <strong>{{ `${plannedVacationDays}` }}</strong>
            <span class="mx-1">von <strong>30</strong></span>
            Urlaubstage geplant (wird automatisch ausgefüllt)
          </div>

          <!-- Planed hours hint for Pädiatrische modules for three years-->
          <div
            v-else-if="
              currentModule.name.includes('Pädiatrische Versorgung') &&
              isThreeYearsTraining
            "
            class="modul-rest-totalHours pl-6"
          >
            <strong
              :style="
                currentModule.plannedHours < currentModule.totalHours
                  ? 'color: red'
                  : 'color: green'
              "
              >{{ `${currentModule.plannedHours}` }}</strong
            >
            <span class="mx-1"
              >von mind.
              <strong>{{ `${currentModule.totalHours}` }}</strong> und max</span
            >
            <strong>120</strong>
            Std. geplant (wird automatisch ausgefüllt)
          </div>

          <!-- Planed hours hint for Pädiatrische modules for two years-->
          <div
            v-else-if="
              currentModule.name.includes('Pädiatrische Versorgung') &&
              !isThreeYearsTraining
            "
            class="modul-rest-totalHours pl-6"
          >
            <strong
              :style="
                currentModule.plannedHours < currentModule.totalHours
                  ? 'color: red'
                  : 'color: green'
              "
              >{{ `${currentModule.plannedHours}` }}</strong
            >
            <span class="mx-1"
              >von exakt.
              <strong>{{ `${currentModule.totalHours}` }}</strong> Std. geplant
              (wird automatisch ausgefüllt)</span
            >
          </div>

          <!-- Planed hours hint for other modules -->
          <div v-else class="modul-rest-totalHours pl-6">
            <strong
              :style="
                currentModule.plannedHours < currentModule.totalHours
                  ? 'color: red'
                  : 'color: green'
              "
              >{{ `${currentModule.plannedHours}` }}</strong
            >
            <span class="mx-1">von mind.</span>
            <strong>{{ `${currentModule.totalHours}` }}</strong>
            Std. geplant (wird automatisch ausgefüllt)
          </div>

          <div class="modul-rest-totalHours mt-0 d-flex align-end">
            <div
              :class="[isNightShiftSwitcherDisabled ? 'disabled-switcher' : '']"
            >
              <v-switch
                v-model="isNightShift"
                @change="$emit('toggle-night-shift', isNightShift)"
                class="mb-1"
                :disabled="isNightShiftSwitcherDisabled"
                label="Nachtdienst"
                color="night-shift-color"
                value="Nachtdienst"
                hide-details
              ></v-switch>
            </div>
            <div>
              <!-- <v-tooltip v-model="isShowNightShiftTooltip" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="primary lighten-1">
                          mdi-information
                        </v-icon>
                      </v-btn>
                    </template>
                    <span @click="isShowNightShiftTooltip = false">
                      Nachtdienste beträgen zwischen 80 und 120 Stunden.
                      <br />
                      Sie haben noch übrig
                      <strong>{{ getFixedNightShiftHours }}</strong>
                      Szunden zu planen.
                      <br />
                      Nachtdienste können erst ab der 2. Hälfte ({{
                        formattedDateOfBeginnSecondTrainingHalf
                      }})
                      <br />
                      der Ausbildung geplant werden.
                    </span>
                  </v-tooltip> -->
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="primary lighten-1"> mdi-information </v-icon>
                  </v-btn>
                </template>
                <span v-if="isThreeYearsTraining">
                  Ab der zweiten Ausbildungshälfte,<br />
                  mind. 80 bis max. 120 Stunden
                </span>
                <span v-else> Mind. 80 bis max. 120 Std. </span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <!-- Chips -->
        <v-combobox
          v-model="dateRangeText"
          chips
          :class="mobileOnly ? 'select-responsive' : 'select-big'"
          dense
          hide-details="true"
          multiple
          outlined
          prepend-icon="mdi-calendar"
          append-icon=""
        >
          >
          <template v-slot:selection="{ item, select, selected, index }">
            <v-chip
              class="my-1"
              :style="mobileOnly ? 'font-size: 12px' : 'font-size: 14px'"
              text-color="white"
              :color="getChipColor(item)"
              small
              :input-value="selected"
              close
              @click="select"
              @click:close="
                $emit('toggle-remove-range', { item: item, index: index })
              "
            >
              {{ item.text }}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <!-- Hint in order to aktivate date picker -->
    <v-row v-if="!isDisableCalendar">
      <v-col cols="12" class="d-flex">
        <v-icon color="primary lighten-1"> mdi-information </v-icon>
        <p class="text-sm-body-2 grey--text align-self-center mb-0 pl-1">
          Tragen Sie bitte ein, wann die Ausbildung anfängt bzw. die
          Arbeitsstunden und die Klasse, um den Kalender zu aktivieren.
        </p>
      </v-col>
    </v-row>

    <v-row v-if="isDisableCalendar && isNoHoursPlanned" no-gutters class="mt-2">
      <v-col cols="12" class="d-flex">
        <v-icon color="primary lighten-1"> mdi-information </v-icon>
        <p class="text-sm-body-2 grey--text align-self-center mb-0 pl-1">
          Sie können erst ab Ausbildungsbeginn
          <span v-if="beginnMonth === '02'">(1. Februar {{ beginnYear }})</span>
          <span v-else>(1. August {{ beginnYear }})</span>
          planen.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import datePickerMixin from "@/mixins/datePickerMixin";
import NotyMessages from "@/helpers/noty";
import ScreenSizeComputed from "@/helpers/screenSize";

export default {
  mixins: [datePickerMixin],
  props: {
    currentModule: {
      typ: Object,
      default: () => {},
    },
    isThreeYearsTraining: {
      typ: Boolean,
      default: true,
    },
    dateRangeText: {
      typ: Array,
      default: () => [],
    },
    actualPlannedNightShiftDates: {
      typ: Array,
      default: () => [],
    },
    isDisableCalendar: {
      typ: Boolean,
      default: true,
    },
    isNightShiftProp: {
      typ: Boolean,
      default: false,
    },
    isNightShiftSwitcherDisabled: {
      typ: Boolean,
      default: true,
    },
    isNoHoursPlanned: {
      typ: Boolean,
      default: true,
    },
    beginnMonth: {
      typ: String,
      default: "",
    },
    beginnYear: {
      typ: String,
      default: "",
    },
    currentYear: {
      typ: String,
      default: "",
    },
  },

  data() {
    return {
      isNightShift: false,
      selectedModule: {},
    };
  },
  computed: {
    ...ScreenSizeComputed,
    ...NotyMessages,

    plannedVacationDays() {
      return this.currentModule.vacationDays.filter((vacationDay) =>
        vacationDay.includes(this.currentYear)
      ).length;
    },
  },
  watch: {
    isNightShiftProp(val) {
      this.isNightShift = val;
    },
  },
  async created() {},
  methods: {
    getChipColor(item) {
      if (this.currentModule.name === "Urlaub") {
        return "#4FB6E3";
      } else if (this.actualPlannedNightShiftDates.includes(item.range[0])) {
        return "night-shift-color";
      } else {
        return "#4FB6E3";
      }
    },
  },
};
</script>
