import Vue from "vue";

export default {
  validate(trainingModules) {
    let nightShiftPlannedHours = 0;

    trainingModules.map((trainingModule) => {
      if (trainingModule.nightShiftRanges.length > 0) {
        trainingModule.nightShiftRanges.map((plannedRange) => {
          nightShiftPlannedHours += plannedRange.plannedRangeHours;
        });
      }
    });

    if (nightShiftPlannedHours < 80) {
      Vue.noty.error(
        `Die Mindeststunden für die Nachtdienste sind noch nicht vollständig geplant. Es fehlern noch ${
          80 - nightShiftPlannedHours
        } Stunden.`
      );
      return false;
    }

    const missedPlannedModule = trainingModules.find((item) => {
      const company = item.trainingCompany;
      return (
        item.plannedHours < item.totalHours ||
        !company.name ||
        !company.street ||
        !company.houseNumber ||
        !company.postCode ||
        company.postCode.length < 5 ||
        company.postCode.length > 5 ||
        !/^\d+$/.test(company.postCode) ||
        !company.place ||
        !company.contactPersonGender ||
        !company.contactPerson ||
        !company.telephone ||
        !company.email
      );
    });

    if (missedPlannedModule && missedPlannedModule.name !== "Urlaub") {
      Vue.noty.error(
        `Der Praxiseinsatz „${missedPlannedModule.name}“ ist noch nicht vollständig ausgefüllt bzw. geplant.`
      );
      return false;
    }

    return true;
  },
};
