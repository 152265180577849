<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="white--text text-h5 lighten-2"
          style="background-color: #4fb6e3"
        >
          Planung fortsetzen
        </v-card-title>

        <v-card-text class="mt-4">
          Sie haben schon eine Planung gespeichert. Möchten Sie die Planung
          fortsetzen?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="$emit('continue-planning')">
            Fortsetzen
          </v-btn>
          <v-btn color="error" text @click="$emit('new-planning')">
            Neu planen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
