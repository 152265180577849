import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vuetify/dist/vuetify.min.css";
import VueNoty from "vuejs-noty";
import "vuejs-noty/dist/vuejs-noty.css";
import vuetify from "./plugins/vuetify";
import store from "./store";
// import customAxios from "./service/axios";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.config.productionTip = false;
Vue.use(VueNoty, { theme: "metroui" });

// const axios = customAxios;
Vue.use(VueAxios, axios);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
