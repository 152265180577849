// import axios from "axios";

const state = {
  twoYearsTrainingData: "",
  schoolVacations: [
    {
      name: "Sommerferien",
      start: "2022-07-07T00:00Z",
      end: "2022-08-17T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2022-10-10T00:00Z",
      end: "2022-10-21T00:00Z",
    },
    {
      name: "Weihnachtsferien",
      start: "2022-12-23T00:00Z",
      end: "2023-01-06T00:00Z",
    },
    {
      name: "Halbjahrespause",
      start: "2023-01-27T00:00Z",
      end: "2023-01-27T00:00Z",
    },
    {
      name: "Osterferien",
      start: "2023-03-06T00:00Z",
      end: "2023-03-17T00:00Z",
    },
    {
      name: "Pfingsferien",
      start: "2023-05-15T00:00Z",
      end: "2023-05-19T00:00Z",
    },
    {
      name: "Sommerferien",
      start: "2023-07-13T00:00Z",
      end: "2023-08-23T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2023-10-02T00:00Z",
      end: "2023-10-02T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2023-10-16T00:00Z",
      end: "2023-10-27T00:00Z",
    },
    {
      name: "Weihnachtsferien",
      start: "2023-12-22T00:00Z",
      end: "2024-01-05T00:00Z",
    },
    {
      name: "Osterferien",
      start: "2024-03-18T00:00Z",
      end: "2024-03-28T00:00Z",
    },
    {
      name: "SingleFerien",
      start: "2024-02-02T00:00Z",
      end: "2024-02-02T00:00Z",
    },
    {
      name: "Pfingsferien",
      start: "2024-05-09T00:00Z",
      end: "2024-05-10T00:00Z",
    },
    {
      name: "Pfingsferien",
      start: "2024-05-20T00:00Z",
      end: "2024-05-24T00:00Z",
    },
    {
      name: "Sommerferien",
      start: "2024-07-18T00:00Z",
      end: "2024-08-28T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2024-10-04T00:00Z",
      end: "2024-10-04T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2024-10-21T00:00Z",
      end: "2024-11-01T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2024-11-01T00:00Z",
      end: "2024-11-01T00:00Z",
    },
    {
      name: "Weihnachtsferien",
      start: "2024-12-20T00:00Z",
      end: "2025-01-03T00:00Z",
    },
    {
      name: "Ferien",
      start: "2025-01-31T00:00Z",
      end: "2025-01-31T00:00Z",
    },
    {
      name: "Frühjahrsferien",
      start: "2025-03-10T00:00Z",
      end: "2025-03-21T00:00Z",
    },
    {
      name: "Pfingstferien",
      start: "2025-05-02T00:00Z",
      end: "2025-05-02T00:00Z",
    },
    {
      name: "Pfingstferien",
      start: "2025-05-26T00:00Z",
      end: "2025-05-30T00:00Z",
    },
    {
      name: "Sommerferien",
      start: "2025-07-24T00:00Z",
      end: "2025-09-03T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2025-10-20T00:00Z",
      end: "2025-10-30T00:00Z",
    },
    {
      name: "Herbstferien",
      start: "2025-10-30T00:00Z",
      end: "2025-10-30T00:00Z",
    },
    {
      name: "Weihnachtsferien",
      start: "2025-12-17T00:00Z",
      end: "2026-01-02T00:00Z",
    },
    {
      name: "Weihnachtsferien",
      start: "2026-01-30T00:00Z",
      end: "2026-01-30T00:00Z",
    },
    {
      name: "Osterferien",
      start: "2026-03-02T00:00Z",
      end: "2026-03-13T00:00Z",
    },
    {
      name: "Pfingstferien",
      start: "2026-05-11T00:00Z",
      end: "2026-05-13T00:00Z",
    },
    {
      name: "Pfingstferien",
      start: "2026-05-15T00:00Z",
      end: "2026-05-15T00:00Z",
    },
    {
      name: "Sommerferien",
      start: "2026-07-09T00:00Z",
      end: "2026-08-19T00:00Z",
    },
    // TODO Provide a short-term solution for vacation scheduling. Fix the bug related to handling months with 31 days.
    {
      name: "Herbstferien",
      start: "2026-10-19T00:00Z",
      end: "2026-10-31T00:00Z",
    },
    {
      name: "Weihnachtsferien",
      start: "2026-12-21T00:00Z",
      end: "2027-01-01T00:00Z",
    },
    {
      name: "Halbjahrespause",
      start: "2027-01-29T00:00Z",
      end: "2027-01-29T00:00Z",
    },
    {
      name: "Osterferien",
      start: "2027-03-01T00:00Z",
      end: "2027-03-12T00:00Z",
    },
    {
      name: "Pfingstferien",
      start: "2027-05-07T00:00Z",
      end: "2027-05-14T00:00Z",
    },
    {
      name: "Sommerferien",
      start: "2027-07-01T00:00Z",
      end: "2027-07-31T00:00Z",
    },
  ],

  feasts: [
    "2022-10-31",
    "2022-12-25",
    "2022-12-26",

    "2023-01-01",
    "2023-04-07",
    "2023-04-10",
    "2023-05-01",
    "2023-05-18",
    "2023-05-29",
    "2023-10-03",
    "2023-10-31",
    "2023-12-25",
    "2023-12-26",

    "2024-01-01",
    "2024-03-29",
    "2024-04-01",
    "2024-05-01",
    "2024-05-09",
    "2024-05-20",
    "2024-10-03",
    "2024-10-31",
    "2024-12-25",
    "2024-12-26",

    "2025-01-01",
    "2025-04-18",
    "2025-04-21",
    "2025-05-01",
    "2025-05-29",
    "2025-06-09",
    "2025-10-03",
    "2025-10-31",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-04-03",
    "2026-04-06",
    "2026-05-01",
    "2026-05-14",
    "2026-05-25",
    "2026-10-03",
    "2026-10-31",
    "2026-12-25",
    "2027-01-01",
    "2027-03-26",
    "2027-03-29",
    "2027-05-17",
  ],
  schoolHolidays: [],
};
const getters = {
  getSchoolHolidays: (state) => state.schoolHolidays,
  getFeasts: (state) => state.feasts,
};
const actions = {
  async fetchVacations({ state }, today) {
    state.schoolVacations.forEach((vacation) => {
      if (vacation.end < today) return;

      let startVacation = new Date(vacation.start);
      let endVacation = new Date(vacation.end);

      while (startVacation <= endVacation) {
        state.schoolHolidays.push(
          new Date(startVacation).toISOString().split("T")[0]
        );

        startVacation.setDate(startVacation.getDate() + 1);
      }
    });
  },
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
