export default {
  lgOnly() {
    return this.$vuetify.breakpoint.lgAndUp;
  },
  ipadOnly() {
    return this.$vuetify.breakpoint.sm;
  },
  mdOnly() {
    return this.$vuetify.breakpoint.md;
  },
  mobileOnly() {
    return this.$vuetify.breakpoint.xs;
  },
  xlOnly() {
    return this.$vuetify.breakpoint.xl;
  },
  phoneOnly() {
    return !this.lgOnly;
  },
};
