// import axios from "axios";

const state = {
  trainingDuration: 3,
  beginnMonth: null,
  beginnYear: null,
  trainingBeginnDate: null,
  dateOfBeginnSecondTrainingHalf: null,
  workingHours: 8,
  twoYearsTreatmentArea: "GPA AP",
  //-------------------------
  blocks: [],
  firstMidtermExamDates: [],
  secondMidtermExamDates: [],
  finalExamDates: [],
  datePickerComponentKey: 0,
};
const getters = {
  getTrainingDuration: (state) => state.trainingDuration,
  getBeginnMonth: (state) => state.beginnMonth,
  getBeginnYear: (state) => state.beginnYear,
  getTrainingBeginnDate: (state) => state.trainingBeginnDate,
  getDateOfBeginnSecondTrainingHalf: (state) =>
    state.dateOfBeginnSecondTrainingHalf,
  getWorkingHours: (state) => state.workingHours,
  getTwoYearsTreatmentArea: (state) => state.twoYearsTreatmentArea,

  getNewTwoYearsItem: (state) => state.newTwoYearsItem,

  getBlocks: (state) => state.blocks,
  getFirstMidtermExamDates: (state) => state.firstMidtermExamDates,
  getSecondMidtermExamDates: (state) => state.secondMidtermExamDates,
  getFinalExamDates: (state) => state.finalExamDates,
  getDatePickerComponentKey: (state) => state.datePickerComponentKey,
};
const actions = {};
const mutations = {
  setTrainingDuration(state, duration) {
    state.trainingDuration = duration;
  },
  setTrainingBeginnMonth(state, month) {
    state.beginnMonth = month;
  },

  setTrainingBeginnYear(state, year) {
    state.beginnYear = year;
  },

  setTrainingBeginnDate(state) {
    state.trainingBeginnDate = `${state.beginnYear}-${state.beginnMonth}-01`;
  },

  setDateOfBeginnSecondTrainingHalf(state) {
    const monthsToAdd = state.trainingDuration === 3 ? 18 : 12;
    const startTrainingDate = new Date(state.trainingBeginnDate);
    const beginnOfSecondHalf = new Date(
      startTrainingDate.setMonth(startTrainingDate.getMonth() + monthsToAdd)
    );
    const year = beginnOfSecondHalf.getFullYear();
    const month = String(beginnOfSecondHalf.getMonth() + 1).padStart(2, "0");
    const day = String(beginnOfSecondHalf.getDate()).padStart(2, "0");

    state.dateOfBeginnSecondTrainingHalf = `${year}-${month}-${day}`;
  },

  setTrainingWorkingHours(state, hours) {
    state.workingHours = hours;
  },

  setClassBlocks(state, blocks) {
    state.blocks = blocks;
  },

  setFirstMidtermExamDates(state, dates) {
    state.firstMidtermExamDates = dates;
  },

  setSecondMidtermExamDates(state, dates) {
    state.secondMidtermExamDates = dates;
  },

  setFinalExamDates(state, dates) {
    state.finalExamDates = dates;
  },

  setDatePickerComponentKey(state) {
    state.datePickerComponentKey++;
  },

  setTwoYearsTreatmentArea(state, value) {
    state.twoYearsTreatmentArea = value;
  },

  setNewTwoYearsItem(state, value) {
    state.newTwoYearsItem = value;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
