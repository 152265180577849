// let todayDate = new Date();
let todayYear = new Date().getFullYear();
let todayMonth = new Date().getMonth() + 1;
let trainingYearItems = [];
// let midtermExamDates = [];
// let finalExamDates = [];

export default {
  getTrainingYearsItems() {
    trainingYearItems = [];
    let lastYear = new Date().getFullYear() - 1;
    let nextYear = new Date().getFullYear() + 1;
    trainingYearItems.push(
      {
        label: String(lastYear),
        value: lastYear,
      },
      {
        label: String(todayYear),
        value: todayYear,
      },
      { label: String(nextYear), value: nextYear }
    );
    return trainingYearItems;
    // await this.setBeginnTrainingDate();
    // this.currentMonthValue = this.beginnMonth;
    // this.currentYearValue = this.beginnYear;
    // this.calculateMiddleExamDates();
    // this.calculateFinaleExamDates();
  },

  setBeginnTrainingDate() {
    if (todayMonth >= 8 && todayMonth <= 12) {
      return ["08", trainingYearItems[1].value];
    } else if (todayMonth < 2) {
      return ["08", trainingYearItems[0].value];
    } else if (todayMonth >= 2 && todayMonth < 8) {
      return ["02", trainingYearItems[1].value];
    }
  },
};
