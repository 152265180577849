export default {
  getMiddleExamDates(beginnYear, beginnMonth, longHolidays, trainingDuration) {
    let middleExamDates = [];
    let datesbetweenExamAndHoliday = [];
    let beginnExamDate = null;
    let formattedDate = null;
    let firstHolidayDateIso2 = null;

    let beginnSecondHalf = null;
    if (trainingDuration === 3) {
      beginnSecondHalf = new Date(beginnYear + 2, beginnMonth - 1);
    }
    if (trainingDuration === 2) {
      beginnSecondHalf = new Date(beginnYear + 1, beginnMonth - 1);
    }

    beginnExamDate = new Date(`${beginnYear}-${beginnMonth}-01`);

    beginnExamDate.setDate(beginnExamDate.getDate() - 7 * 8);

    if (trainingDuration === 3) {
      beginnExamDate.setFullYear(beginnExamDate.getFullYear() + 2);
    }
    if (trainingDuration === 2) {
      beginnExamDate.setFullYear(beginnExamDate.getFullYear() + 1);
    }

    beginnExamDate = beginnExamDate.toISOString().split("T")[0];
    let firstHolidayDateIso = longHolidays.find(
      (date) =>
        date >= beginnExamDate && date < this.getIsoFormat(beginnSecondHalf)
    );
    firstHolidayDateIso2 = firstHolidayDateIso;
    if (firstHolidayDateIso2 !== undefined) {
      firstHolidayDateIso2 = new Date(firstHolidayDateIso2);
      if (
        firstHolidayDateIso2.getDay() !== 6 &&
        firstHolidayDateIso2.getDay() !== 7
      ) {
        let lastFridayDate =
          new Date(firstHolidayDateIso2).getDate() +
          (6 - new Date(firstHolidayDateIso2).getDay() - 1) -
          7;
        let firstHolidayDate = new Date(firstHolidayDateIso2);
        firstHolidayDate.setDate(lastFridayDate);
        let startDate = firstHolidayDate;
        startDate.setDate(startDate.getDate() + 3);
        let endDate = new Date(firstHolidayDateIso);

        while (startDate <= endDate) {
          datesbetweenExamAndHoliday.push(this.getIsoFormat(startDate));
          startDate.setDate(startDate.getDate() + 1);
        }
        lastFridayDate =
          new Date(firstHolidayDateIso2).getDate() +
          (6 - new Date(firstHolidayDateIso2).getDay() - 1) -
          7;
        firstHolidayDate = new Date(firstHolidayDateIso2);
        firstHolidayDate.setDate(lastFridayDate);
        firstHolidayDateIso2 = firstHolidayDate;
        firstHolidayDateIso2.setDate(firstHolidayDateIso2.getDate() + 3);
      }
      firstHolidayDateIso2.setDate(firstHolidayDateIso2.getDate() - 7 * 8);
      firstHolidayDateIso2 = firstHolidayDateIso2.toISOString().split("T")[0];
      beginnExamDate = firstHolidayDateIso2;
    }
    let startDate = new Date(beginnExamDate);
    let endDate = beginnSecondHalf;
    while (startDate <= endDate) {
      formattedDate = this.getIsoFormat(startDate);
      if (!datesbetweenExamAndHoliday.includes(formattedDate)) {
        middleExamDates.push(formattedDate);
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    return [middleExamDates, datesbetweenExamAndHoliday];
  },

  getFinalExamDates(beginnYear, beginnMonth, trainingDuration) {
    let finalExamDates = [];
    let formattedFinalExamDates = null;
    let endTrainingDate = null;
    if (trainingDuration === 3) {
      endTrainingDate = `${beginnYear + 3}-${beginnMonth - 1}-01`;
    }
    if (trainingDuration === 2) {
      endTrainingDate = `${beginnYear + 2}-${beginnMonth - 1}-01`;
    }
    endTrainingDate = new Date(endTrainingDate);
    let firstDayOfFinalExam = new Date(
      endTrainingDate.getFullYear(),
      endTrainingDate.getMonth() + 1,
      0
    );
    let lastDayOfTraining = new Date(
      endTrainingDate.getFullYear(),
      endTrainingDate.getMonth() + 1,
      0
    );
    firstDayOfFinalExam.setDate(firstDayOfFinalExam.getDate() - 7 * 8);
    let startDate = firstDayOfFinalExam;
    let endDate = lastDayOfTraining;

    while (startDate <= endDate) {
      formattedFinalExamDates = this.getIsoFormat(startDate);
      finalExamDates.push(formattedFinalExamDates);
      startDate.setDate(startDate.getDate() + 1);
    }
    return finalExamDates;
  },
  getIsoFormat(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  },
};
