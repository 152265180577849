<template>
  <v-app-bar
    :style="lgOnly ? 'height: 100px' : 'height: 65px'"
    color="white"
    id="navbar"
    flat
  >
    <v-container
      :class="
        lgOnly ? 'd-flex pt-12 container align-center' : 'container-mobile'
      "
    >
      <a
        target="_blank"
        href="https://www.berufliche-schule-burgstrasse.de/bildungsangebot/ausbildung-pflege-und-gesundheit/"
      >
        <h1>Berufliche Schule Burgstraße</h1>
        <!-- <h2 v-else>Berufliche Schule Burgstraße</h2> -->
      </a>
      <v-spacer v-if="lgOnly" />
      <!-- v-if="lgOnly" -->
      <a
        v-if="lgOnly"
        target="_blank"
        href="https://www.berufliche-schule-burgstrasse.de/bildungsangebot/ausbildung-pflege-und-gesundheit/"
      >
        <img
          :style="lgOnly ? 'max-width: 50px' : 'max-width: 40px'"
          src="@/assets/Logo.png"
          alt="Logo"
        />
      </a>
    </v-container>
  </v-app-bar>
</template>

<script>
import GlobalComputed from "@/helpers/screenSize";
export default {
  components: {},
  data() {
    return {};
  },
  computed: { ...GlobalComputed },
  methods: {},
};
</script>

<style lang="scss" scopef>
#navbar {
  // height: 100px !important;
  box-shadow: 0 0 transparent, 0 0 transparent,
    0 5px 5px -4px rgba(0, 0, 0, 0.1) !important;
}
.container {
  width: 80% !important;
}
.container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
a {
  text-decoration: none;
}
</style>
