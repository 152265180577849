import Vue from "vue";

export default {
  calculateNightShiftDates(
    dateRange,
    currentModule,
    workingHours,
    blocks,
    nightShiftHours,
    feasts,
    actualPlannedVacationDates
  ) {
    if (
      currentModule.name.includes("Pädiatrische Versorgung") &&
      currentModule.plannedHours >= 120
    ) {
      Vue.noty.error(
        "Dieser Praxiseinsatz muss mind. 80 und max. 120 Stunden betragen."
      );
      return [false];
    }

    const [start, end] = dateRange.map((date) => new Date(date));

    let rangeHoures = 0;
    let tempoArray = [];
    let formattedStartDate = null;

    if (nightShiftHours >= 120) {
      Vue.noty.error(
        "Nachtdienste müssen mind. 80 und max. 120 Stunden betragen."
      );
      return [false];
    }

    let hasAlreadyReachedAllowedHours = false;

    let plannedDates = [];

    const feastsSet = new Set(feasts);
    const actualPlannedVacationDatesSet = new Set(actualPlannedVacationDates);

    if (start.getHours() !== end.getHours()) {
      start.setHours(1);
      end.setHours(1);
    }

    while (start <= end) {
      hasAlreadyReachedAllowedHours =
        tempoArray.length * workingHours + nightShiftHours >= 120;

      if (hasAlreadyReachedAllowedHours) {
        dateRange[0] = tempoArray[0];
        dateRange[1] = tempoArray[tempoArray.length - 1];

        rangeHoures = tempoArray.length * workingHours;
        plannedDates = plannedDates.concat(tempoArray);
        return [true, plannedDates, rangeHoures];
      }

      formattedStartDate = this.formatDate(start);

      if (
        !(start.getDay() === 6 || start.getDay() === 0) &&
        !feastsSet.has(formattedStartDate) &&
        !actualPlannedVacationDatesSet.has(formattedStartDate) &&
        !blocks.some(
          (block) =>
            formattedStartDate >= block.start && formattedStartDate <= block.end
        )
      ) {
        tempoArray.push(formattedStartDate);
      }

      start.setDate(start.getDate() + 1);
    }

    rangeHoures = tempoArray.length * workingHours;

    plannedDates = plannedDates.concat(tempoArray);
    return [true, plannedDates, rangeHoures];
  },

  //------------------------------------------------------------------------------
  calculatePaediatrischeVersorgungModule(
    dateRange,
    currentModule,
    workingHours,
    blocks,
    feasts,
    actualPlannedVacationDates
  ) {
    const [start, end] = dateRange.map((date) => new Date(date));
    const plannedHours = currentModule.plannedHours;

    let rangeHoures = 0;
    let tempoArray = [];
    let formattedStartDate = null;

    if (plannedHours === 120) {
      Vue.noty.error("Dieser Praxiseinsatz muss exakt 120 Stunden betragen.");
      return [false];
    }

    let hasAlreadyReachedHours = false;

    let plannedDates = [];

    const feastsSet = new Set(feasts);
    const actualPlannedVacationDatesSet = new Set(actualPlannedVacationDates);

    if (start.getHours() !== end.getHours()) {
      start.setHours(1);
      end.setHours(1);
    }

    while (start <= end) {
      hasAlreadyReachedHours =
        plannedHours + tempoArray.length * workingHours >= 120;

      if (hasAlreadyReachedHours) {
        dateRange[0] = tempoArray[0];
        dateRange[1] = tempoArray[tempoArray.length - 1];

        rangeHoures = tempoArray.length * workingHours;

        plannedDates = plannedDates.concat(tempoArray);

        return [true, plannedDates, rangeHoures];
      }

      formattedStartDate = this.formatDate(start);

      if (
        !(start.getDay() === 6 || start.getDay() === 0) &&
        !feastsSet.has(formattedStartDate) &&
        !actualPlannedVacationDatesSet.has(formattedStartDate) &&
        !blocks.some(
          (block) =>
            formattedStartDate >= block.start && formattedStartDate <= block.end
        )
      ) {
        tempoArray.push(formattedStartDate);
      }

      start.setDate(start.getDate() + 1);
    }

    rangeHoures = tempoArray.length * workingHours;

    return [true, tempoArray, rangeHoures];
    // if (currentModule.plannedHours + rangeHoures < 120) {
    //   Vue.noty.error(
    //     "Dieser Praxiseinsatz muss an einem Stück geplant werden."
    //   );
    //   return [false];
    // }
  },

  //------------------------------------------------------------------------------
  calculatePsychiatrischeVersorgung(
    dateRange,
    // currentModule,
    workingHours,
    blocks,
    feasts,
    // finalExamDates
    actualPlannedVacationDates
  ) {
    const [start, end] = dateRange.map((date) => new Date(date));

    // const formattedEndDate = new Date(
    //   end.getTime() - end.getTimezoneOffset() * 60000
    // )
    //   .toISOString()
    //   .split("T")[0];

    // if (finalExamDates.includes(formattedEndDate)) {
    //   Vue.noty.error(
    //     "Der Praxiseinsatz mit exakt 120 Stunden darf nicht im Zeitraum der Prüfung geplant werden."
    //   );
    //   return [false];
    // }

    let rangeHoures = 0;
    let tempoArray = [];
    let formattedStartDate = null;

    // let plannedDates = [];

    const feastsSet = new Set(feasts);
    const actualPlannedVacationDatesSet = new Set(actualPlannedVacationDates);

    if (start.getHours() !== end.getHours()) {
      start.setHours(1);
      end.setHours(1);
    }

    while (start <= end) {
      formattedStartDate = this.formatDate(start);

      if (
        !(start.getDay() === 6 || start.getDay() === 0) &&
        !feastsSet.has(formattedStartDate) &&
        !actualPlannedVacationDatesSet.has(formattedStartDate) &&
        !blocks.some(
          (block) =>
            formattedStartDate >= block.start && formattedStartDate <= block.end
        )
      ) {
        tempoArray.push(formattedStartDate);
      }

      start.setDate(start.getDate() + 1);
    }

    rangeHoures = tempoArray.length * workingHours;

    // if (currentModule.plannedHours + rangeHoures < 120) {
    //   Vue.noty.error(
    //     "Dieser Praxiseinsatz muss an einem Stück geplant werden."
    //   );
    //   return [false];
    // }

    return [true, tempoArray, rangeHoures];

    // if (currentModule.plannedHours + rangeHoures >= 120) {
    //   plannedDates = plannedDates.concat(tempoArray);
    //   return [true, plannedDates, rangeHoures];
    // }
  },

  //------------------------------------------------------------------------------
  calculateModuleRange(
    dateRange,
    // currentModule,
    workingHours,
    blocks,
    feasts,
    // finalExamDates
    actualPlannedVacationDates
  ) {
    const [start, end] = dateRange.map((date) => new Date(date));

    let rangeHoures = 0;
    let formattedStartDate = null;

    let plannedDates = [];

    const feastsSet = new Set(feasts);
    const actualPlannedVacationDatesSet = new Set(actualPlannedVacationDates);

    if (start.getHours() !== end.getHours()) {
      start.setHours(1);
      end.setHours(1);
    }

    while (start <= end) {
      formattedStartDate = this.formatDate(start);

      if (
        !(start.getDay() === 6 || start.getDay() === 0) &&
        !feastsSet.has(formattedStartDate) &&
        !actualPlannedVacationDatesSet.has(formattedStartDate) &&
        !blocks.some(
          (block) =>
            formattedStartDate >= block.start && formattedStartDate <= block.end
        )
      ) {
        plannedDates.push(formattedStartDate);
      }

      start.setDate(start.getDate() + 1);
    }

    rangeHoures = plannedDates.length * workingHours;

    return [true, plannedDates, rangeHoures];
  },

  calculateVacationModule(
    dateRange,
    currentModule,
    blocks,
    feasts,
    currentYear,
    actualPlannedVacationDates
  ) {
    const [start, end] = dateRange.map((date) => new Date(date));

    let vacationDays = [];
    let formattedStartDate = null;

    let vacationDaysForCurrentYear = [];

    if (currentModule.vacationDays !== undefined) {
      vacationDaysForCurrentYear = currentModule.vacationDays.filter(
        (vacationDay) => vacationDay.includes(currentYear)
      );
    }

    if (vacationDaysForCurrentYear.length >= 30) {
      Vue.noty.error("30 Urlaubstage für dieses Jahr sind schon geplant.");
      return [false];
    }

    const actualPlannedVacationDatesSet = new Set(actualPlannedVacationDates);

    if (start.getHours() !== end.getHours()) {
      start.setHours(1);
      end.setHours(1);
    }

    while (start <= end) {
      formattedStartDate = this.formatDate(start);

      if (
        !(start.getDay() === 6 || start.getDay() === 0) &&
        vacationDaysForCurrentYear.length + vacationDays.length + 1 <= 30 &&
        !blocks.some(
          (block) =>
            formattedStartDate >= block.start && formattedStartDate <= block.end
        ) &&
        !actualPlannedVacationDatesSet.has(formattedStartDate) &&
        !feasts.includes(formattedStartDate)
      ) {
        vacationDays.push(formattedStartDate);
      }
      start.setDate(start.getDate() + 1);
    }

    return [true, vacationDays];
  },

  removeModuleDateRange(
    range,
    rangeIndex,
    currentModule,
    actualPlannedDayShiftDates,
    actualPlannedNightShiftDates,
    workingHours,
    dateRangeText,
    nightShiftHours
  ) {
    let plannedDayShiftDatesDates = [...actualPlannedDayShiftDates];
    let plannedNightShiftDatesDates = [...actualPlannedNightShiftDates];

    let moduleToEdit = JSON.parse(JSON.stringify(currentModule));
    let rangeTexts = [...dateRangeText];

    let plannedNightShiftHours = nightShiftHours;
    let indexToDelete = null;

    const [startDate, endDate] = [range.range[0], range.range[1]];

    let foundRange = null;
    let foundRangeIndex = null;

    // Check and delete dates from day shift array
    const foundDayShiftDatesToDelete = actualPlannedDayShiftDates.filter(
      (date) => date >= startDate && date <= endDate
    );

    if (foundDayShiftDatesToDelete.length > 0) {
      foundDayShiftDatesToDelete.forEach((dateToDelete) => {
        indexToDelete = plannedDayShiftDatesDates.indexOf(dateToDelete);
        plannedDayShiftDatesDates.splice(indexToDelete, 1);
      });

      foundRange = moduleToEdit.dayShiftRanges.find(
        (date) => date.start === range.range[0]
      );

      foundRangeIndex = moduleToEdit.dayShiftRanges.indexOf(foundRange);
      moduleToEdit.dayShiftRanges.splice(foundRangeIndex, 1);
    }

    // Check and delete dates from night shift array
    const foundNightShiftDatesToDelete = plannedNightShiftDatesDates.filter(
      (date) => date >= startDate && date <= endDate
    );

    if (foundNightShiftDatesToDelete.length > 0) {
      foundNightShiftDatesToDelete.forEach((dateToDelete) => {
        indexToDelete = plannedNightShiftDatesDates.indexOf(dateToDelete);
        plannedNightShiftDatesDates.splice(indexToDelete, 1);
      });

      foundRange = moduleToEdit.nightShiftRanges.find(
        (date) => date.start === range.range[0]
      );

      foundRangeIndex = moduleToEdit.nightShiftRanges.indexOf(foundRange);
      moduleToEdit.nightShiftRanges.splice(foundRangeIndex, 1);
      plannedNightShiftHours -= foundRange.plannedRangeHours;
    }

    // delete hours from current module
    if (moduleToEdit.plannedHours - foundRange.plannedRangeHours < 0) {
      moduleToEdit.plannedHours = 0;
    } else {
      moduleToEdit.plannedHours -= foundRange.plannedRangeHours;
    }

    if (workingHours % 1 != 0) {
      if (moduleToEdit.plannedHours > 0) {
        moduleToEdit.plannedHours = moduleToEdit.plannedHours.toFixed(1);
        moduleToEdit.plannedHours = parseFloat(moduleToEdit.plannedHours);
      } else {
        moduleToEdit.plannedHours = 0;
      }
    }

    rangeTexts.splice(rangeIndex, 1);

    return [
      plannedDayShiftDatesDates,
      plannedNightShiftDatesDates,
      moduleToEdit,
      rangeTexts,
      plannedNightShiftHours,
    ];
  },

  removeVacationDateRange(
    range,
    rangeIndex,
    currentModule,
    dateRangeText,
    actualPlannedVacationDates
  ) {
    let moduleToEdit = JSON.parse(JSON.stringify(currentModule));
    let rangeTexts = [...dateRangeText];
    let plannedVacationDays = [...actualPlannedVacationDates];

    const foundRange = moduleToEdit.dayShiftRanges.find(
      (date) => date.start === range.range[0]
    );

    const foundRangeIndex = moduleToEdit.dayShiftRanges.indexOf(foundRange);
    const startDate = new Date(foundRange.start);
    const endDate = new Date(foundRange.end);

    // endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());

    const formattedStartDate = this.formatDate(startDate);
    const formattedEndDate = this.formatDate(endDate);

    const datesToRemove = plannedVacationDays.filter(
      (date) => date >= formattedStartDate && date <= formattedEndDate
    );

    plannedVacationDays = plannedVacationDays.filter(
      (date) => !datesToRemove.includes(date)
    );

    moduleToEdit.vacationDays = plannedVacationDays.filter(
      (date) => !datesToRemove.includes(date)
    );

    moduleToEdit.dayShiftRanges.splice(foundRangeIndex, 1);
    rangeTexts.splice(rangeIndex, 1);

    return [moduleToEdit, rangeTexts, plannedVacationDays];
  },

  formatDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  },
};
