import Vue from "vue";
import VueRouter from "vue-router";
// import FormCard from "@/components/FormCard";
import Start from "@/components/Start";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
