<template>
  <div id="student-form">
    <v-form ref="studentForm">
      <v-card class="px-6" :style="mobileOnly || ipadOnly ? 'width: 100%' : ''">
        <h1 class="pt-8">Über Ihre:n Auszubildende:n</h1>
        <v-row class="mt-0">
          <v-col cols="12" sm="6" md="12" lg="6" class="pb-0">
            <label class="d-flex flex-column w-100 gap" />
            * Name
            <v-text-field
              v-model="studentData.lastname"
              outlined
              :rules="lastnameRules"
              dense
              id="name"
            />
          </v-col>
          <v-col cols="12" sm="6" md="12" lg="6" class="pb-0">
            <label class="d-flex flex-column w-100 gap" />
            * Vorname
            <v-text-field
              v-model="studentData.firstname"
              outlined
              :rules="firstnameRules"
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex pt-0">
            <v-icon color="primary lighten-1"> mdi-information </v-icon>
            <p
              class="text-sm-body-2 grey--text align-self-center pt-0 mb-0 pl-1"
            >
              Bitte geben Sie die vollständigen Vor- und Nachnamen an.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <label class="d-flex flex-column w-100 gap" />
            * Klasse
            <v-select
              :key="inputSelectKey"
              :items="classItems"
              :value="studentData.class"
              item-text="name"
              item-value="name"
              dense
              outlined
              @change="toggleChangeClass"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <ErrorDialog v-if="isClassErrorDialog" @close="closeErrorDialog" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import GlobalComputed from "@/helpers/screenSize";
import ClassesAndBlocks from "@/helpers/classesAndBlocks";
import ErrorDialog from "@/components/form/shared/ErrorDialog";

export default {
  components: {
    ErrorDialog,
  },
  props: {},

  data() {
    return {
      isClassErrorDialog: false,
      studentData: {},
      inputSelectKey: 0,
      isStudentName: true,
      newClassValue: "",
      lastnameRules: [
        (v) => !!v || "Name ist erforderlich",
        (v) => !/\d/.test(v) || "Kein gültiger Wert!",
      ],
      firstnameRules: [
        (v) => !!v || "Vorname ist erforderlich",
        (v) => !/\d/.test(v) || "Kein gültiger Wert!",
      ],
    };
  },

  computed: {
    ...GlobalComputed,
    ...ClassesAndBlocks,
    ...mapGetters("TrainingSettings", {
      getTrainingDuration: "getTrainingDuration",
    }),

    ...mapGetters("TrainingModules", {
      getCurrentTrainingModules: "getCurrentTrainingModules",
      getStudentData: "getStudentData",
    }),

    trainingModules() {
      return this.getCurrentTrainingModules;
    },

    trainingDuration() {
      return this.getTrainingDuration;
    },
    storeStudentData() {
      return this.getStudentData;
    },

    classItems() {
      return this.trainingDuration === 3
        ? ClassesAndBlocks.getThreeYearsTrainingClasses()
        : ClassesAndBlocks.getTwoYearsTrainingClasses();
    },
  },

  watch: {
    trainingDuration() {
      this.studentData.class = this.classItems[0].name;
      this.getClassBlocks();
    },
  },

  created() {
    this.studentData.class = this.classItems[0].name;
    this.checkStoreStudentData();
    this.getClassBlocks();
  },

  methods: {
    ...mapMutations("TrainingSettings", {
      setClassBlocks: "setClassBlocks",
      setDatePickerComponentKey: "setDatePickerComponentKey",
    }),

    ...mapMutations("TrainingModules", {
      setStudentData: "setStudentData",
      resetAllModulesPlannedHoures: "resetAllModulesPlannedHoures",
    }),

    checkStoreStudentData() {
      if (this.storeStudentData.class !== "") {
        this.studentData.lastname = this.storeStudentData.lastname;
        this.studentData.firstname = this.storeStudentData.firstname;
        this.studentData.class = this.storeStudentData.class;
      }
    },

    validateForm() {
      if (!this.$refs.studentForm.validate()) {
        const element = document.getElementById("name");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        return false;
      } else {
        this.setStudentData(this.studentData);
        return true;
      }
    },

    toggleChangeClass(chosenClass) {
      if (
        this.trainingModules.find(
          (trainingModule) => trainingModule.plannedHours > 0
        )
      ) {
        this.isClassErrorDialog = true;
        this.newClassValue = chosenClass;
        return;
      }
      this.studentData.class = chosenClass;
      this.getClassBlocks();
    },

    closeErrorDialog(value) {
      if (value) {
        this.resetAllModulesPlannedHoures();
        this.studentData.class = this.newClassValue;
        this.getClassBlocks();
        this.setDatePickerComponentKey();
      } else {
        this.inputSelectKey++;
      }
      this.isClassErrorDialog = false;
    },

    getClassBlocks() {
      if (this.trainingDuration === 3) {
        this.setClassBlocks(
          ClassesAndBlocks.getThreeYearsClassBlocks(this.studentData.class)
        );
      } else {
        this.setClassBlocks(
          ClassesAndBlocks.getTwoYearsClassBlocks(this.studentData.class)
        );
      }
    },
  },
};
</script>
