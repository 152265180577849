<template>
  <div id="buttons-card">
    <v-card class="py-5 px-6">
      <div class="d-flex mb-6">
        <v-file-input
          v-model="chosenFile"
          accept=".json"
          @change="importJson()"
          :clearable="false"
          outlined
          hide-details=""
          dense
        >
        </v-file-input>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="align-self-center">
              <v-icon color="primary lighten-1"> mdi-information </v-icon>
            </v-btn>
          </template>
          <span
            >Laden Sie die erstellte JSON-Datei hoch, um den Ausbildungsplan<br />
            wieder zu öffnen und ggf. anzupassen</span
          >
        </v-tooltip>
      </div>
      <v-row>
        <v-col
          cols="11"
          sm="4"
          md="4"
          xs="5"
          class="d-flex"
          :class="mobileOnly ? 'pr-4' : ''"
        >
          <v-btn
            :block="mobileOnly"
            color="success"
            @click="() => $emit('save-page')"
            dark
          >
            Speichern
            <v-icon dark right> mdi-content-save </v-icon>
          </v-btn>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="align-self-center">
                <v-icon color="primary lighten-1"> mdi-information </v-icon>
              </v-btn>
            </template>
            <span
              >Sie können jederzeit die Planung im Hintergrund speichern,<br />
              um später fortzusetzen. <br />
              Wenn Sie die Seite wieder öffnen, wird die gespeicherte
              <br />Planung automtaisch geladen oder Sie können neu
              planen.</span
            >
          </v-tooltip>
        </v-col>
        <v-col
          sm="4"
          md="4"
          cols="11"
          xs="5"
          class="d-flex justify-center"
          :class="mobileOnly ? 'pr-4' : ''"
        >
          <v-btn
            :block="mobileOnly"
            color="error"
            @click="() => $emit('reset-page')"
            dark
          >
            Löschen
            <v-icon dark right> mdi-delete </v-icon>
          </v-btn>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="align-self-center">
                <v-icon color="primary lighten-1"> mdi-information </v-icon>
              </v-btn>
            </template>
            <span>Alle Eingaben zurücksetzen.</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="11"
          sm="4"
          md="4"
          class="d-flex"
          :class="mobileOnly ? 'pr-4 justify-start' : 'justify-end'"
        >
          <v-btn
            :block="mobileOnly"
            color="#4fb6e3"
            @click="() => $emit('download')"
            dark
          >
            Datei
            <v-icon dark right> mdi-download </v-icon>
          </v-btn>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="align-self-center">
                <v-icon color="primary lighten-1"> mdi-information </v-icon>
              </v-btn>
            </template>
            <span>
              Sie können die Datei erst auf Ihren Computer herunterladen,
              <br />wenn Sie alle Eingabefelder vollständig ausgefüllt haben.<br />
              Behalten Sie bitte unbedingt den vorgegebenen Dateinamen bei.
              <br />
              Senden Sie die Datei anschließend per E-Mail an <br />
              ausbildungsplan@burgstrasse.net. <br />Ist die Datei bei uns
              eingegangen und geprüft, erhalten Sie <br />eine ausdruckbare
              PDF-Datei zugeschickt.
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import GlobalComputed from "@/helpers/screenSize";
export default {
  components: {},
  data() {
    return {
      chosenFile: null,
    };
  },
  computed: {
    ...GlobalComputed,
    ...mapGetters("TrainingSettings", {
      getTrainingDuration: "getTrainingDuration",
      getWorkingHours: "getWorkingHours",
      getBeginnMonth: "getBeginnMonth",
      getBeginnYear: "getBeginnYear",
    }),
    trainingDuration() {
      return this.getTrainingDuration;
    },
  },
  watch: {},
  async created() {},
  methods: {
    importJson() {
      if (!this.chosenFile) {
        this.data = "No File Chosen";
      }
      var reader = new FileReader();
      reader.readAsText(this.chosenFile);
      reader.onload = (e) => {
        let result = JSON.parse(e.target.result);

        if (result.modules[0].nightShiftRanges === undefined) {
          this.$emit("error-old-version");
        } else if (
          parseInt(result.trainingDuration) !== this.trainingDuration
        ) {
          result = null;
          this.$emit("error-import-duration");
        } else {
          //TODO For the new release. Check if there is no vacation Module
          // To delete in the future
          this.checkVacationModule(result);
          // this.$emit("import-json", result);
        }
      };
    },
    checkVacationModule(modules) {
      let trainingModules = { ...modules };
      if (
        !trainingModules.modules.find(
          (trainingModule) => trainingModule.name === "Urlaub"
        )
      ) {
        trainingModules.modules.push({
          name: "Urlaub",
          totalHours: 0,
          plannedHours: 0,
          dayShiftRanges: [],
          nightShiftRanges: [],
          vacationDays: [],
          trainingYear: 0,
          trainingCompany: {
            name: "",
            contactPerson: "",
            contactPersonGender: "",
            street: "",
            houseNumber: "",
            postCode: "",
            place: "",
            telephone: "",
            email: "",
          },
        });
      }
      this.$emit("import-json", trainingModules);
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  background-color: rgb(99, 98, 98) !important;
  opacity: 1 !important;
}
</style>
