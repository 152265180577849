var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"buttons-card"}},[_c('v-card',{staticClass:"py-5 px-6"},[_c('div',{staticClass:"d-flex mb-6"},[_c('v-file-input',{attrs:{"accept":".json","clearable":false,"outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.importJson()}},model:{value:(_vm.chosenFile),callback:function ($$v) {_vm.chosenFile=$$v},expression:"chosenFile"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v("Laden Sie die erstellte JSON-Datei hoch, um den Ausbildungsplan"),_c('br'),_vm._v(" wieder zu öffnen und ggf. anzupassen")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex",class:_vm.mobileOnly ? 'pr-4' : '',attrs:{"cols":"11","sm":"4","md":"4","xs":"5"}},[_c('v-btn',{attrs:{"block":_vm.mobileOnly,"color":"success","dark":""},on:{"click":function () { return _vm.$emit('save-page'); }}},[_vm._v(" Speichern "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v("Sie können jederzeit die Planung im Hintergrund speichern,"),_c('br'),_vm._v(" um später fortzusetzen. "),_c('br'),_vm._v(" Wenn Sie die Seite wieder öffnen, wird die gespeicherte "),_c('br'),_vm._v("Planung automtaisch geladen oder Sie können neu planen.")])])],1),_c('v-col',{staticClass:"d-flex justify-center",class:_vm.mobileOnly ? 'pr-4' : '',attrs:{"sm":"4","md":"4","cols":"11","xs":"5"}},[_c('v-btn',{attrs:{"block":_vm.mobileOnly,"color":"error","dark":""},on:{"click":function () { return _vm.$emit('reset-page'); }}},[_vm._v(" Löschen "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-delete ")])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v("Alle Eingaben zurücksetzen.")])])],1),_c('v-col',{staticClass:"d-flex",class:_vm.mobileOnly ? 'pr-4 justify-start' : 'justify-end',attrs:{"cols":"11","sm":"4","md":"4"}},[_c('v-btn',{attrs:{"block":_vm.mobileOnly,"color":"#4fb6e3","dark":""},on:{"click":function () { return _vm.$emit('download'); }}},[_vm._v(" Datei "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-download ")])],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v(" Sie können die Datei erst auf Ihren Computer herunterladen, "),_c('br'),_vm._v("wenn Sie alle Eingabefelder vollständig ausgefüllt haben."),_c('br'),_vm._v(" Behalten Sie bitte unbedingt den vorgegebenen Dateinamen bei. "),_c('br'),_vm._v(" Senden Sie die Datei anschließend per E-Mail an "),_c('br'),_vm._v(" ausbildungsplan@burgstrasse.net. "),_c('br'),_vm._v("Ist die Datei bei uns eingegangen und geprüft, erhalten Sie "),_c('br'),_vm._v("eine ausdruckbare PDF-Datei zugeschickt. ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }