export default {
  daysColor(
    dayDate,
    schoolHolidays,
    feasts,
    actuallPlanedDates,
    nightShiftDates,
    middleExamDates,
    blocks,
    finalExamDates,
    vacationDays
  ) {
    const day = new Date(dayDate);

    const isWeekend = day.getDay() === 6 || day.getDay() === 0;

    const isBlock = blocks.find(
      (date) => date.start <= dayDate && date.end >= dayDate
    );

    const isMiddleExam = middleExamDates.includes(dayDate);
    const isFinalExam = finalExamDates.includes(dayDate);
    const isModulePlanedDate = actuallPlanedDates.includes(dayDate);
    const isNightShiftPlanedDate = nightShiftDates.includes(dayDate);
    const isSchoolHolidays = schoolHolidays.includes(dayDate);
    const isTakenVacationDate = vacationDays.includes(dayDate);

    if (feasts.includes(dayDate)) {
      if (isWeekend || isBlock) {
        return ["feast-color", "grey"];
      } else {
        return "feast-color";
      }
    }

    if (isMiddleExam) {
      if (isWeekend || isBlock) {
        return ["orange", "grey"];
      } else if (isSchoolHolidays) {
        if (
          isModulePlanedDate &&
          !isNightShiftPlanedDate &&
          isTakenVacationDate
        ) {
          return ["orange", "red", "taken-vacation-color"];
        } else if (isNightShiftPlanedDate && isTakenVacationDate) {
          return ["orange", "red", "taken-vacation-color"];
        } else if (isModulePlanedDate && !isNightShiftPlanedDate) {
          return ["orange", "red", "green"];
        } else if (isNightShiftPlanedDate) {
          return ["orange", "red", "night-shift-color"];
        } else if (isTakenVacationDate) {
          return ["orange", "red", "taken-vacation-color"];
        } else return ["orange", "red"];
      } else if (isNightShiftPlanedDate && isTakenVacationDate) {
        return ["orange", "taken-vacation-color"];
      } else if (isNightShiftPlanedDate) {
        return ["orange", "night-shift-color"];
      } else if (isModulePlanedDate && isTakenVacationDate) {
        return ["orange", "taken-vacation-color"];
      } else if (isModulePlanedDate) {
        return ["orange", "green"];
      } else if (isTakenVacationDate) {
        return ["orange", "taken-vacation-color"];
      } else {
        return "orange";
      }
    }

    if (isFinalExam) {
      if (isWeekend || isBlock) {
        return ["final-exams-color", "grey"];
      } else if (isSchoolHolidays) {
        if (
          isModulePlanedDate &&
          !isNightShiftPlanedDate &&
          isTakenVacationDate
        ) {
          return ["final-exams-color", "red", "taken-vacation-color"];
        } else if (isNightShiftPlanedDate && isTakenVacationDate) {
          return ["final-exams-color", "red", "taken-vacation-color"];
        } else if (isModulePlanedDate && !isNightShiftPlanedDate) {
          return ["final-exams-color", "red", "green"];
        } else if (isNightShiftPlanedDate) {
          return ["final-exams-color", "red", "night-shift-color"];
        } else if (isTakenVacationDate) {
          return ["final-exams-color", "red", "taken-vacation-color"];
        } else return ["final-exams-color", "red"];
      } else if (isNightShiftPlanedDate && isTakenVacationDate) {
        return ["final-exams-color", "taken-vacation-color"];
      } else if (isNightShiftPlanedDate) {
        return ["final-exams-color", "night-shift-color"];
      } else if (isModulePlanedDate && isTakenVacationDate) {
        return ["final-exams-color", "taken-vacation-color"];
      } else if (isModulePlanedDate) {
        return ["final-exams-color", "green"];
      } else if (isTakenVacationDate) {
        return ["final-exams-color", "taken-vacation-color"];
      } else {
        return "final-exams-color";
      }
    }

    if (isSchoolHolidays) {
      if (isWeekend || isBlock) {
        return ["red", "grey"];
      } else if (isNightShiftPlanedDate && isTakenVacationDate) {
        return ["red", "taken-vacation-color"];
      } else if (isNightShiftPlanedDate) {
        return ["red", "night-shift-color"];
      } else if (isModulePlanedDate && isTakenVacationDate) {
        return ["red", "taken-vacation-color"];
      } else if (isModulePlanedDate) {
        return ["red", "green"];
      } else if (isTakenVacationDate) {
        return ["red", "taken-vacation-color"];
      } else {
        return "red";
      }
    }

    if (isTakenVacationDate && isModulePlanedDate) {
      return "taken-vacation-color";
    }

    if (isTakenVacationDate && isNightShiftPlanedDate) {
      return "taken-vacation-color";
    }

    if (isNightShiftPlanedDate && isModulePlanedDate) {
      return "night-shift-color";
    }

    if (isNightShiftPlanedDate) {
      return "night-shift-color";
    }

    if (isModulePlanedDate) {
      return "green";
    }

    if (isTakenVacationDate) {
      return "taken-vacation-color";
    }

    if (isWeekend || isBlock) {
      return "grey";
    }
  },

  getIsDateAllowed(
    date,
    currentModule,
    firstMidtermExamDates,
    schoolHolidays,
    actualPlannedDayShiftDates,
    actualPlannedNightShiftDates,
    feasts,
    trainingBeginnDate,
    blocks,
    finalExamDates,
    actualPlannedVacationDates
  ) {
    let isDateAllowed = false;
    const day = new Date(date).getDay();

    if (date < trainingBeginnDate) {
      return false;
    }

    if (date > finalExamDates[finalExamDates.length - 1]) {
      return false;
    } else if (date) {
      if (currentModule.trainingYear === 1) {
        isDateAllowed =
          date <= firstMidtermExamDates[firstMidtermExamDates.length - 1];
      } else if (currentModule.trainingYear === 2) {
        isDateAllowed =
          date > firstMidtermExamDates[firstMidtermExamDates.length - 1];
      }

      const feastsSet = new Set(feasts);
      // const blocksSet = new Set(blocks.map((block) => block.start));
      // const holidaysSet = new Set(schoolHolidays);
      const daysSchiftsSet = new Set(actualPlannedDayShiftDates);
      const nightsShiftsSet = new Set(actualPlannedNightShiftDates);
      const takenVacationSet = new Set(actualPlannedVacationDates);

      // if (currentModule.totalHours === 120) {
      //   return (
      //     !schoolHolidays.includes(date) &&
      //     !(day.getDay() === 6 || day.getDay() === 0) &&
      //     !actualPlannedDayShiftDates.includes(date) &&
      //     !feasts.includes(date) &&
      //     isDateAllowed &&
      //     date >= trainingBeginnDate &&
      //     !blocks.find((date) => date.start <= date && date.end >= date)
      //   );
      // } else {

      return (
        !(day === 6 || day === 0) &&
        isDateAllowed &&
        !feastsSet.has(date) &&
        !daysSchiftsSet.has(date) &&
        !nightsShiftsSet.has(date) &&
        !takenVacationSet.has(date) &&
        !blocks.find(
          (blockDate) => blockDate.start <= date && blockDate.end >= date
        ) &&
        date >= trainingBeginnDate
      );
    }
  },

  getIsDateAllowedForVacationModule(
    date,
    actualPlannedDayShiftDates,
    actualPlannedNightShiftDates,
    feasts,
    trainingBeginnDate,
    blocks,
    finalExamDates,
    actualPlannedVacationDates
  ) {
    const day = new Date(date).getDay();
    if (date < trainingBeginnDate) {
      return false;
    }

    if (date > finalExamDates[finalExamDates.length - 1]) {
      return false;
    } else if (date) {
      return (
        !(day === 6 || day === 0) &&
        !actualPlannedDayShiftDates.includes(date) &&
        !actualPlannedNightShiftDates.includes(date) &&
        !feasts.includes(date) &&
        !actualPlannedVacationDates.includes(date) &&
        !blocks.find(
          (blockDate) => blockDate.start <= date && blockDate.end >= date
        )
      );
    }
  },
};
