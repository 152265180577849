import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        "taken-vacation-color": "#3d2194",
        "feast-color": "#D63693",
        "disabled-color": "#757575",
        "final-exams-color": "#2196F3",
        "night-shift-color": "#6D330D",
      },
    },
  },
});
