<template>
  <v-row class="mt-0">
    <v-col cols="12" class="mt-1">
      <div>
        *<strong class="mx-1">{{ getNumberOfPlannedModules }}</strong>
        von
        <strong>{{ modules.length }}</strong> Praxiseinsätzen geplant
      </div>
      <v-select
        v-model="selectedModule"
        @change="handleChangeModule"
        :items="modules"
        item-color=""
        hide-details
        dense
        outlined
      >
        <!-- For current item -->
        <template v-slot:selection="{ item }">
          {{ item.name }}
          <v-spacer></v-spacer>

          <div v-if="getIsDoneStatus(item)">
            <v-icon color="success"> mdi-check </v-icon>
          </div>

          <div v-if="!(getIsDoneStatus(item) || getIsHoursNotice(item))">
            <v-icon> mdi-pencil </v-icon>
          </div>
        </template>

        <!-- For all items in the list -->
        <template v-slot:item="{ item }">
          {{ item.name }}
          <v-spacer></v-spacer>

          <v-list-item-action
            v-if="getIsDoneStatus(item)"
            class="mr-0"
            @click.stop
          >
            <v-icon color="success"> mdi-check </v-icon>
          </v-list-item-action>

          <v-list-item-action
            v-else-if="!(getIsDoneStatus(item) || getIsHoursNotice(item))"
            class="mr-0"
            @click.stop
          >
            <v-icon color="grey"> mdi-pencil </v-icon>
          </v-list-item-action>

          <v-list-item-action v-else class="mr-0" @click.stop>
            <div v-if="item.name !== 'Urlaub'">
              <span
                v-if="
                  item.name.includes('Pädiatrische Versorgung') &&
                  !isThreeYearsTraining
                "
                >exakt
                {{ `${item.totalHours} Std.` }}
              </span>
              <span
                v-else-if="
                  item.name.includes('Pädiatrische Versorgung') &&
                  isThreeYearsTraining
                "
                >80 - 120 Std.</span
              >
              <span v-if="!item.name.includes('Pädiatrische Versorgung')"
                >mind.

                {{ `${item.totalHours} Std.` }}
              </span>
            </div>

            <div v-else>
              {{ isThreeYearsTraining ? "90 Tage" : "60 Tage" }}
            </div>
          </v-list-item-action>
        </template>
      </v-select>
    </v-col>

    <v-col cols="12" class="d-flex pt-0">
      <v-icon color="primary lighten-1" class="mr-1 mb-4">
        mdi-information
      </v-icon>

      <div
        v-if="isThreeYearsTraining"
        class="text-sm-body-2 grey--text align-self-center"
      >
        <p>{{ getThreeYearsSelectedModuleInfoText(currentModule) }}</p>
      </div>

      <div v-else class="text-sm-body-2 grey--text align-self-center">
        <p>{{ getTwoYearsSelectedModuleInfoText(currentModule) }}</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import datePickerMixin from "@/mixins/datePickerMixin";
import NotyMessages from "@/helpers/noty";

export default {
  mixins: [datePickerMixin],
  props: {
    modules: {
      typ: Array,
      default: () => [],
    },
    currentModule: {
      typ: Object,
      default: () => {},
    },
    isThreeYearsTraining: {
      typ: Boolean,
      default: true,
    },
    numberOfPlannedModules: {
      typ: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedModule: {},
    };
  },
  computed: {
    ...NotyMessages,

    ...mapGetters("TrainingModules", {
      getNumberOfPlannedModules: "getNumberOfPlannedModules",
    }),
  },
  watch: {},
  async created() {
    this.selectedModule = { ...this.currentModule };
  },
  methods: {
    handleChangeModule(module) {
      if (this.isThreeYearsTraining) {
        NotyMessages.getThreeYearsNotyMessage(module);
      } else {
        NotyMessages.getTwoYearsNotyMessage(module);
      }
      this.$emit("change-selected-module", module);
    },
  },
};
</script>
