var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('v-row',{staticClass:"mt-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.currentModule.name === 'Urlaub')?_c('div',{staticClass:"modul-rest-totalHours pl-6"},[_c('strong',[_vm._v(_vm._s(("" + _vm.plannedVacationDays)))]),_c('span',{staticClass:"mx-1"},[_vm._v("von "),_c('strong',[_vm._v("30")])]),_vm._v(" Urlaubstage geplant (wird automatisch ausgefüllt) ")]):(
            _vm.currentModule.name.includes('Pädiatrische Versorgung') &&
            _vm.isThreeYearsTraining
          )?_c('div',{staticClass:"modul-rest-totalHours pl-6"},[_c('strong',{style:(_vm.currentModule.plannedHours < _vm.currentModule.totalHours
                ? 'color: red'
                : 'color: green')},[_vm._v(_vm._s(("" + (_vm.currentModule.plannedHours))))]),_c('span',{staticClass:"mx-1"},[_vm._v("von mind. "),_c('strong',[_vm._v(_vm._s(("" + (_vm.currentModule.totalHours))))]),_vm._v(" und max")]),_c('strong',[_vm._v("120")]),_vm._v(" Std. geplant (wird automatisch ausgefüllt) ")]):(
            _vm.currentModule.name.includes('Pädiatrische Versorgung') &&
            !_vm.isThreeYearsTraining
          )?_c('div',{staticClass:"modul-rest-totalHours pl-6"},[_c('strong',{style:(_vm.currentModule.plannedHours < _vm.currentModule.totalHours
                ? 'color: red'
                : 'color: green')},[_vm._v(_vm._s(("" + (_vm.currentModule.plannedHours))))]),_c('span',{staticClass:"mx-1"},[_vm._v("von exakt. "),_c('strong',[_vm._v(_vm._s(("" + (_vm.currentModule.totalHours))))]),_vm._v(" Std. geplant (wird automatisch ausgefüllt)")])]):_c('div',{staticClass:"modul-rest-totalHours pl-6"},[_c('strong',{style:(_vm.currentModule.plannedHours < _vm.currentModule.totalHours
                ? 'color: red'
                : 'color: green')},[_vm._v(_vm._s(("" + (_vm.currentModule.plannedHours))))]),_c('span',{staticClass:"mx-1"},[_vm._v("von mind.")]),_c('strong',[_vm._v(_vm._s(("" + (_vm.currentModule.totalHours))))]),_vm._v(" Std. geplant (wird automatisch ausgefüllt) ")]),_c('div',{staticClass:"modul-rest-totalHours mt-0 d-flex align-end"},[_c('div',{class:[_vm.isNightShiftSwitcherDisabled ? 'disabled-switcher' : '']},[_c('v-switch',{staticClass:"mb-1",attrs:{"disabled":_vm.isNightShiftSwitcherDisabled,"label":"Nachtdienst","color":"night-shift-color","value":"Nachtdienst","hide-details":""},on:{"change":function($event){return _vm.$emit('toggle-night-shift', _vm.isNightShift)}},model:{value:(_vm.isNightShift),callback:function ($$v) {_vm.isNightShift=$$v},expression:"isNightShift"}})],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")])],1)]}}])},[(_vm.isThreeYearsTraining)?_c('span',[_vm._v(" Ab der zweiten Ausbildungshälfte,"),_c('br'),_vm._v(" mind. 80 bis max. 120 Stunden ")]):_c('span',[_vm._v(" Mind. 80 bis max. 120 Std. ")])])],1)])]),_c('v-combobox',{class:_vm.mobileOnly ? 'select-responsive' : 'select-big',attrs:{"chips":"","dense":"","hide-details":"true","multiple":"","outlined":"","prepend-icon":"mdi-calendar","append-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var select = ref.select;
                var selected = ref.selected;
                var index = ref.index;
return [_c('v-chip',{staticClass:"my-1",style:(_vm.mobileOnly ? 'font-size: 12px' : 'font-size: 14px'),attrs:{"text-color":"white","color":_vm.getChipColor(item),"small":"","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.$emit('toggle-remove-range', { item: item, index: index })}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},[_vm._v(" > ")])],1)],1),(!_vm.isDisableCalendar)?_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")]),_c('p',{staticClass:"text-sm-body-2 grey--text align-self-center mb-0 pl-1"},[_vm._v(" Tragen Sie bitte ein, wann die Ausbildung anfängt bzw. die Arbeitsstunden und die Klasse, um den Kalender zu aktivieren. ")])],1)],1):_vm._e(),(_vm.isDisableCalendar && _vm.isNoHoursPlanned)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")]),_c('p',{staticClass:"text-sm-body-2 grey--text align-self-center mb-0 pl-1"},[_vm._v(" Sie können erst ab Ausbildungsbeginn "),(_vm.beginnMonth === '02')?_c('span',[_vm._v("(1. Februar "+_vm._s(_vm.beginnYear)+")")]):_c('span',[_vm._v("(1. August "+_vm._s(_vm.beginnYear)+")")]),_vm._v(" planen. ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }