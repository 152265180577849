<template>
  <v-row>
    <v-col :class="!mobileOnly ? 'd-flex py-0' : 'py-0'" cols="12">
      <div class="d-flex align-center green--text">
        <v-icon color="green" large>mdi-circle-medium</v-icon>
        Praxiseinsätze
      </div>
      <div class="d-flex align-center red--text">
        <v-icon color="red" large>mdi-circle-medium</v-icon>
        Schulferien
      </div>
      <div class="d-flex align-center disabled-color--text">
        <v-icon color="disabled-color" large>mdi-circle-medium</v-icon>
        Schulblöcke, Wochenende
      </div>
      <div class="d-flex align-center feast-color--text">
        <v-icon color="feast-color" large>mdi-circle-medium</v-icon>
        Feiertage
      </div>
      <div class="d-flex align-center taken-vacation-color--text">
        <v-icon color="taken-vacation-color" large>mdi-circle-medium</v-icon>
        Urlaub
      </div>
    </v-col>
    <v-col :class="!mobileOnly ? 'd-flex py-0' : 'py-0'" cols="12">
      <div class="d-flex align-center orange--text">
        <v-icon color="orange" large>mdi-circle-medium</v-icon>
        Zeitraum für Zwischenprüfung
      </div>
      <div class="d-flex align-center blue--text">
        <v-icon color="blue" large>mdi-circle-medium</v-icon>
        Zeitraum für Abschlussprüfung
      </div>
      <div class="d-flex align-center night-shift-color--text">
        <v-icon color="night-shift-color" large>mdi-circle-medium</v-icon>
        Nachtdienste
      </div>
    </v-col>
  </v-row>
</template>

<script>
import GlobalComputed from "@/helpers/screenSize";

export default {
  computed: {
    ...GlobalComputed,
  },
};
</script>
