<template>
  <div id="student-form">
    <v-form ref="studentForm">
      <v-card class="px-6" :style="mobileOnly || ipadOnly ? 'width: 100%' : ''">
        <h1 class="pt-8">Über die Ausbildung</h1>

        <v-row class="mt-1">
          <v-col cols="12">
            <label class="d-flex flex-column w-100 gap" />
            * Ausbildungsdauer
            <!-- v-model="trainingDuration" -->
            <v-select
              :items="trainingDurationItems"
              :value="trainingDuration"
              :key="inputFieldKey"
              hide-details="true"
              item-text="label"
              item-value="value"
              dense
              outlined
              @change="changeTrainingDuration"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="11"
            :class="[savedPlansNames.length === 1 ? 'grey--text' : '', 'pr-0']"
          >
            <label class="d-flex flex-column w-100" />
            Gespeicherte Pläne für die
            <strong v-if="trainingDuration === 3">dreijährige</strong>
            <strong v-if="trainingDuration === 2">zweijährige</strong>
            Ausbildung
            <v-select
              :items="savedPlansNames"
              :value="currentSavedPlanItem"
              :disabled="savedPlansNames.length === 1"
              hide-details="true"
              item-text="name"
              item-value="value"
              dense
              outlined
              @change="toggleChangeSavedPlane($event)"
            ></v-select>
          </v-col>
          <v-col cols="1" class="d-flex align-center mt-5 pl-2">
            <v-tooltip v-model="show" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="primary lighten-1"> mdi-information </v-icon>
                </v-btn>
              </template>
              <span @click="show = false">
                Um Konflikte zu vermeiden, ist es nicht möglich, die
                <br />
                Ausbildungsdauer für einen gespeicherten Plan zu ändern.
                <br />
                Wenn Sie die Ausbildungsdauer für einen gespeicherten Plan
                <br />
                ändern möchten, löschen Sie diesen Plan und planen Sie neu.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
          <v-col v-if="savedPlansNames.length < 2" cols="12" class="d-flex">
            <v-icon color="primary lighten-1"> mdi-information </v-icon>
            <p class="text-sm-body-2 grey--text align-self-center mb-0 pl-1">
              Es existieren noch keine gespeicherten Pläne für die
              <span v-if="trainingDuration === 3">dreijährige</span>
              <span v-else>zweijährige</span>
              Ausbildung
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :class="[trainingDuration === 3 ? 'grey--text' : '']"
          >
            <label class="d-flex flex-column w-100" />
            * Versorgungsbereich für die <strong>zweijährige</strong>
            Ausbildung
            <v-select
              :value="twoYearsTreatmentArea"
              :items="twoYearsTrainingItems"
              :key="inputFieldKey"
              :disabled="trainingDuration === 3"
              hide-details="true"
              item-text="label"
              item-value="value"
              dense
              outlined
              @change="toggleChangeTwoYearsTreatmentArea($event)"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="12" lg="6" class="">
            <label class="d-flex flex-column w-100" />
            * Monat
            <v-select
              :value="beginnMonth"
              :items="trainingMothItems"
              :key="inputFieldKey"
              item-text="label"
              item-value="value"
              hide-details
              @change="toggleNewTrainingMonth($event)"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="12" lg="6" class="">
            <label class="d-flex flex-column w-100" />
            * Jahr
            <v-select
              :value="beginnYear"
              :items="beginnYearItems"
              :key="inputFieldKey"
              hide-details
              item-text="label"
              item-value="value"
              @change="toggleNewTrainingYear($event)"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="12" lg="12" class="pb-0">
            <label class="d-flex flex-column w-100" />
            <p class="working-hours mb-0">* Tägliche Arbeitsstunden</p>
            <v-text-field
              :value="workingHours"
              :key="inputFieldKey"
              :hide-details="isHoursValid"
              :error-messages="hoursErrorMessage"
              dense
              @input="checkWorkingHours"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-icon color="primary lighten-1"> mdi-information </v-icon>
            <p class="text-sm-body-2 grey--text align-self-center mb-0 pl-1">
              Tragen Sie bitte den Ausbildungsbeginn und die tägliche
              Arbeitszeit Ihrer/Ihres Auszubildenden ein. Letzteres ist wichtig
              für Ihre Prüfung, ob die Mindeststunden der Praxiseinsätze
              erreicht sind.
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <ErrorDialog
      v-if="isDurationDialogError"
      @close="closeDurationErrorDialog"
    />
    <!-- <ErrorDialog
      v-if="isSavedPlanDialogError"
      @close="closeSavedPlanErrorDialog"
    /> -->
    <ErrorDialog
      v-if="isTreatmentAreaDialogError"
      @close="closeTreatmentAreaErrorDialog"
    />
    <ErrorDialog v-if="isMonthErrorDialog" @close="closeMonthErrorDialog" />
    <ErrorDialog v-if="isYearErrorDialog" @close="closeYearErrorDialog" />
    <ErrorDialog v-if="isHoursErrorDialog" @close="closeHoursDialog" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import GlobalComputed from "@/helpers/screenSize";
import ClassesAndBlocks from "@/helpers/classesAndBlocks";
// import CalculateDates from "@/helpers/calculateDates";
import examsDates from "@/helpers/examsDates";
import ErrorDialog from "@/components/form/shared/ErrorDialog";
import CalculateDates from "@/helpers/calculateDates";
export default {
  components: {
    ErrorDialog,
  },
  props: {},
  data() {
    return {
      trainingDurationItems: [
        { value: 3, label: "Drei Jahre" },
        { value: 2, label: "Zwei Jahre" },
      ],
      // trainingDuration: null,
      twoYearsTrainingItems: ["GPA AP", "GPA LZP", "GPA AP/LP"],
      trainingMothItems: [],
      // beginnMonth: null,
      beginnYearItems: [],
      // beginnYear: null,
      isHoursValid: true,
      hoursErrorMessage: "",
      midtermExamDates: [],
      datesBetweenMidtermExamDates: [],
      inputFieldKey: 0,
      newDurationValue: null,
      isDurationDialogError: false,
      isTreatmentAreaDialogError: false,
      newTreatmentAreaValue: null,
      isMonthErrorDialog: false,
      newMonthValue: null,
      isYearErrorDialog: false,
      newYearValue: null,
      isHoursErrorDialog: false,
      newHoursValue: null,
      show: false,
    };
  },
  watch: {},
  computed: {
    ...GlobalComputed,
    ...ClassesAndBlocks,

    ...mapGetters("App", {
      getSchoolHolidays: "getSchoolHolidays",
    }),

    ...mapGetters("TrainingModules", {
      getCurrentTrainingModules: "getCurrentTrainingModules",
      getSavedPlansNames: "getSavedPlansNames",
      getCurrentSavedPlanName: "getCurrentSavedPlanName",
      getCurrentSavedPlans: "getCurrentSavedPlans",
    }),

    ...mapGetters("TrainingSettings", {
      getTrainingDuration: "getTrainingDuration",
      getBeginnMonth: "getBeginnMonth",
      getBeginnYear: "getBeginnYear",
      getWorkingHours: "getWorkingHours",
      getTwoYearsTreatmentArea: "getTwoYearsTreatmentArea",
    }),

    trainingDuration() {
      return this.getTrainingDuration;
    },

    schoolHolidays() {
      return this.getSchoolHolidays;
    },

    trainingModules() {
      return this.getCurrentTrainingModules;
    },

    twoYearsTreatmentArea() {
      return this.getTwoYearsTreatmentArea;
    },

    workingHours() {
      return this.getWorkingHours;
    },

    beginnMonth() {
      return this.getBeginnMonth;
    },

    beginnYear() {
      return this.getBeginnYear;
    },

    savedPlansNames() {
      return this.getSavedPlansNames;
    },

    currentSavedPlanItem() {
      return this.getCurrentSavedPlanName;
    },
  },

  created() {
    this.setMonthItems();
    this.setYearItems();
    this.getbeginnOfTrainingDates();
    this.calculateMidtermExamDates();
    this.calculateFinaleExamDates();
    // this.emitData();
    // this.getMonthValue();
  },

  methods: {
    // ...mapMutations("App", {
    // }),
    ...mapMutations("TrainingSettings", {
      setTrainingDuration: "setTrainingDuration",
      setTrainingBeginnMonth: "setTrainingBeginnMonth",
      setTrainingBeginnYear: "setTrainingBeginnYear",
      setTrainingBeginnDate: "setTrainingBeginnDate",
      setDateOfBeginnSecondTrainingHalf: "setDateOfBeginnSecondTrainingHalf",
      setTrainingWorkingHours: "setTrainingWorkingHours",
      setTwoYearsTreatmentArea: "setTwoYearsTreatmentArea",

      setFirstMidtermExamDates: "setFirstMidtermExamDates",
      setSecondMidtermExamDates: "setSecondMidtermExamDates",
      setFinalExamDates: "setFinalExamDates",
      setDatePickerComponentKey: "setDatePickerComponentKey",

      setNewTrainingBeginnMonth: "setNewTrainingBeginnMonth",
      setNewTwoYearsItem: "setNewTwoYearsItem",
    }),

    ...mapActions("TrainingModules", {
      checkLocalStorage: "checkLocalStorage",
    }),

    ...mapMutations("TrainingModules", {
      setCurrentTrainingModules: "setCurrentTrainingModules",
      resetAllModulesPlannedHoures: "resetAllModulesPlannedHoures",
      setCurrentSavedPlanName: "setCurrentSavedPlanName",
    }),
    //toggle change training duration

    //Add months
    setMonthItems() {
      this.trainingMothItems = [
        { label: "Februar", value: "02" },
        { label: "August", value: "08" },
      ];
    },

    // Set beginn training month Bei creating the component====================>
    getbeginnOfTrainingDates() {
      if (this.beginnMonth !== null && this.beginnYear !== null) return;
      const [beginnMonth, beginnYear] = CalculateDates.setBeginnTrainingDate();
      this.setTrainingBeginnMonth(beginnMonth);
      this.setTrainingBeginnYear(beginnYear);
      this.setTrainingBeginnDate();
      this.setDateOfBeginnSecondTrainingHalf();
    },

    // Set year items Bei creating the component===============================>
    setYearItems() {
      this.beginnYearItems = CalculateDates.getTrainingYearsItems();
    },

    //Toggle changing training duration========================================>
    changeTrainingDuration(chosenDurationValue) {
      if (
        !this.isPlanAlreadySaved(chosenDurationValue) &&
        this.isModulesPlanned()
      ) {
        this.newDurationValue = chosenDurationValue;
        this.isDurationDialogError = true;
        return;
      }

      if (chosenDurationValue === 3) {
        this.setCurrentTrainingModules({ trainingDuration: 3, item: null });
      } else {
        this.setCurrentTrainingModules({ trainingDuration: 2, item: "GPA AP" });
      }
      this.setTrainingDuration(chosenDurationValue);
      sessionStorage.setItem("training-duration", chosenDurationValue);
      this.checkLocalStorage(chosenDurationValue);
      this.setCurrentSavedPlanName(this.savedPlansNames[0]);
      this.calculateMidtermExamDates();
      this.calculateFinaleExamDates();
      this.setDatePickerComponentKey();
    },

    //Check if plan saved========================================>
    isPlanAlreadySaved(chosenDurationValue) {
      if (
        this.getCurrentSavedPlans.find(
          (savedPlan) =>
            `${savedPlan.student.firstname} ${savedPlan.student.lastname}` ===
            this.currentSavedPlanItem.name
        )
      ) {
        this.$emit("save-page");
        sessionStorage.setItem("training-duration", chosenDurationValue);
        this.setTrainingDuration(chosenDurationValue);
        this.$emit("set-next-plan", "Neuer Plan");
        return true;
      } else {
        return false;
      }
    },

    //Toggle close error dialog for training duration==========================>
    closeDurationErrorDialog(dialogValue) {
      if (dialogValue) {
        this.resetAllModulesPlannedHoures();
        this.setTrainingDuration(this.newDurationValue);
        if (this.getTrainingDuration === 3) {
          this.setCurrentTrainingModules({ trainingDuration: 3, item: null });
        } else {
          this.setCurrentTrainingModules({
            trainingDuration: 2,
            item: "GPA AP",
          });
        }
        this.calculateMidtermExamDates();
        this.calculateFinaleExamDates();
        this.setDatePickerComponentKey();
        this.checkLocalStorage(this.newDurationValue);
        this.setCurrentSavedPlanName(this.savedPlansNames[0]);
      } else {
        this.inputFieldKey++;
      }
      this.isDurationDialogError = false;
    },

    //Toggle select saved plan==============================================>
    toggleChangeSavedPlane(newPlan) {
      this.$emit("save-page");
      this.$emit("set-next-plan", newPlan);
    },

    //Toggle close error dialog for importing a saved plan=====================>
    //<=========================================================================

    //Toggle select a treatment area===========================================>
    toggleChangeTwoYearsTreatmentArea(newTreatmentArea) {
      if (this.isModulesPlanned()) {
        this.newTreatmentAreaValue = newTreatmentArea;
        this.isTreatmentAreaDialogError = true;
        return;
      }
      this.setTwoYearsTreatmentArea(newTreatmentArea);
      this.setCurrentTrainingModules({
        trainingDuration: 2,
        item: newTreatmentArea,
      });
      this.setDatePickerComponentKey();
    },

    //Toggle close error dialog for select a treatment area====================>
    closeTreatmentAreaErrorDialog(dialogValue) {
      if (dialogValue) {
        this.resetAllModulesPlannedHoures();
        this.setTwoYearsTreatmentArea(this.newTreatmentAreaValue);
        this.setCurrentTrainingModules({
          trainingDuration: 2,
          item: this.newTreatmentAreaValue,
        });
        this.setDatePickerComponentKey();
      } else {
        this.inputFieldKey++;
      }
      this.isTreatmentAreaDialogError = false;
    },

    //Toggle changing start month==============================================>
    toggleNewTrainingMonth(chosenMonth) {
      if (this.isModulesPlanned()) {
        this.newMonthValue = chosenMonth;
        this.isMonthErrorDialog = true;
        return;
      }

      this.setTrainingBeginnMonth(chosenMonth);
      this.setTrainingBeginnDate();
      this.setDateOfBeginnSecondTrainingHalf();
      this.calculateMidtermExamDates();
      this.calculateFinaleExamDates();
    },

    //Toggle close error dialog for changing start month=======================>
    closeMonthErrorDialog(dialogValue) {
      if (dialogValue) {
        this.resetAllModulesPlannedHoures();

        this.setTrainingBeginnMonth(this.newMonthValue);
        this.setTrainingBeginnDate();
        this.setDateOfBeginnSecondTrainingHalf();
        this.calculateMidtermExamDates();
        this.calculateFinaleExamDates();

        this.setDatePickerComponentKey();
      } else {
        this.inputFieldKey++;
      }
      this.isMonthErrorDialog = false;
    },

    //Toggle changing start year===============================================>
    toggleNewTrainingYear(chosenYear) {
      if (this.isModulesPlanned()) {
        this.newYearValue = chosenYear;
        this.isYearErrorDialog = true;
        return;
      }
      this.setTrainingBeginnYear(chosenYear);
      this.setTrainingBeginnDate();
      this.setDateOfBeginnSecondTrainingHalf();
      this.calculateMidtermExamDates();
      this.calculateFinaleExamDates();
    },

    //Toggle close error dialog for changing start year========================>
    closeYearErrorDialog(dialogValue) {
      if (dialogValue) {
        this.resetAllModulesPlannedHoures();

        this.setTrainingBeginnYear(this.newYearValue);
        this.setTrainingBeginnDate();
        this.setDateOfBeginnSecondTrainingHalf();
        this.calculateMidtermExamDates();
        this.calculateFinaleExamDates();

        this.setDatePickerComponentKey();
      } else {
        this.inputFieldKey++;
      }
      this.isYearErrorDialog = false;
    },

    //Toggle check working hours input=========================================>
    checkWorkingHours(val) {
      if (this.isModulesPlanned()) {
        this.isHoursErrorDialog = true;
        return;
      }

      if (val.includes(".") || val.includes(",")) {
        val = val.replace(",", ".");
      }
      if (!isNaN(val) && val !== "") {
        if (val % 1 === 0) {
          val = parseInt(val);
          // this.setTrainingWorkingHours(parseInt(val));
          // this.workingHours = parseInt(val);
        } else {
          val = parseFloat(val);
          // this.setTrainingWorkingHours(parseFloat(val));

          // this.workingHours = parseFloat(val);
        }
      }
      this.isHoursValid = !(
        val < 5 ||
        val > 10 ||
        /[a-zA-Z-!@#$%^&*()_+\-=[\]{};':"\\|<>/?]/g.test(val)
      );
      if (!this.isHoursValid) {
        this.hoursErrorMessage = "Kein gültiger Wert!";
        this.setTrainingWorkingHours("");
      } else {
        this.hoursErrorMessage = "";
        this.changeTrainingWorkingHours(val);
      }
    },

    //Toggle changing working hours============================================>
    changeTrainingWorkingHours(newWorkinHours) {
      if (this.isModulesPlanned()) {
        this.newHoursValue = newWorkinHours;
        this.isHoursErrorDialog = true;
        return;
      }
      this.setTrainingWorkingHours(newWorkinHours);
    },

    //Toggle close error dialog for changing working hours=====================>
    closeHoursDialog(dialogValue) {
      if (dialogValue) {
        this.resetAllModulesPlannedHoures();
        this.setTrainingWorkingHours(this.newHoursValue);
        this.setDatePickerComponentKey();
      } else {
        this.inputFieldKey++;
      }
      this.isHoursErrorDialog = false;
    },

    // Calculate midterm exam dates============================================>
    calculateMidtermExamDates() {
      const [firstDates, seconDates] = examsDates.getMiddleExamDates(
        this.beginnYear,
        this.beginnMonth,
        this.schoolHolidays,
        this.trainingDuration
      );
      this.setFirstMidtermExamDates(firstDates);
      this.setSecondMidtermExamDates(seconDates);
    },

    // Calculate final exam dates==============================================>
    calculateFinaleExamDates() {
      const finalExamDates = examsDates.getFinalExamDates(
        this.beginnYear,
        this.beginnMonth,
        this.trainingDuration
      );
      this.setFinalExamDates(finalExamDates);
    },

    // Check if some modules hours are already planned=========================>
    isModulesPlanned() {
      return this.trainingModules.find(
        (trainingModule) =>
          trainingModule.dayShiftRanges.length > 0 ||
          trainingModule.nightShiftRanges.length > 0
      );
    },
  },
};
</script>
