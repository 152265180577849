var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('div',[_vm._v(" *"),_c('strong',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.getNumberOfPlannedModules))]),_vm._v(" von "),_c('strong',[_vm._v(_vm._s(_vm.modules.length))]),_vm._v(" Praxiseinsätzen geplant ")]),_c('v-select',{attrs:{"items":_vm.modules,"item-color":"","hide-details":"","dense":"","outlined":""},on:{"change":_vm.handleChangeModule},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer'),(_vm.getIsDoneStatus(item))?_c('div',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1):_vm._e(),(!(_vm.getIsDoneStatus(item) || _vm.getIsHoursNotice(item)))?_c('div',[_c('v-icon',[_vm._v(" mdi-pencil ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer'),(_vm.getIsDoneStatus(item))?_c('v-list-item-action',{staticClass:"mr-0",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1):(!(_vm.getIsDoneStatus(item) || _vm.getIsHoursNotice(item)))?_c('v-list-item-action',{staticClass:"mr-0",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-pencil ")])],1):_c('v-list-item-action',{staticClass:"mr-0",on:{"click":function($event){$event.stopPropagation();}}},[(item.name !== 'Urlaub')?_c('div',[(
                item.name.includes('Pädiatrische Versorgung') &&
                !_vm.isThreeYearsTraining
              )?_c('span',[_vm._v("exakt "+_vm._s(((item.totalHours) + " Std."))+" ")]):(
                item.name.includes('Pädiatrische Versorgung') &&
                _vm.isThreeYearsTraining
              )?_c('span',[_vm._v("80 - 120 Std.")]):_vm._e(),(!item.name.includes('Pädiatrische Versorgung'))?_c('span',[_vm._v("mind. "+_vm._s(((item.totalHours) + " Std."))+" ")]):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(_vm.isThreeYearsTraining ? "90 Tage" : "60 Tage")+" ")])])]}}]),model:{value:(_vm.selectedModule),callback:function ($$v) {_vm.selectedModule=$$v},expression:"selectedModule"}})],1),_c('v-col',{staticClass:"d-flex pt-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mr-1 mb-4",attrs:{"color":"primary lighten-1"}},[_vm._v(" mdi-information ")]),(_vm.isThreeYearsTraining)?_c('div',{staticClass:"text-sm-body-2 grey--text align-self-center"},[_c('p',[_vm._v(_vm._s(_vm.getThreeYearsSelectedModuleInfoText(_vm.currentModule)))])]):_c('div',{staticClass:"text-sm-body-2 grey--text align-self-center"},[_c('p',[_vm._v(_vm._s(_vm.getTwoYearsSelectedModuleInfoText(_vm.currentModule)))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }