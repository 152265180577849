<template>
  <div id="start">
    <v-container
      :class="[
        xlOnly ? 'big-screen-container' : 'small-screen-container',
        'pt-10',
      ]"
    >
      <TrainingOptionsCard
        :key="optionsComponentKey + 'A'"
        @save-page="startSavingPlanInLocalStorageProzess"
        @set-next-plan="getDataFromLocalStorage"
      />
      <StudentFormComponent
        class="mt-11"
        :key="studentComponentKey + 'B'"
        ref="studentForm"
      />
      <v-card class="px-6 my-11">
        <h1 class="pt-8">
          Über Sie als Träger der praktischen Ausbildung (TpA)
        </h1>
        <ContactForm
          :key="companyComponentKey + 'C'"
          :data="getCompanyData"
          @company-data="($event) => setCompanyData($event)"
          ref="companyForm"
        />
      </v-card>
      <ThreeYearsTrainingForm
        :key="datePickerKey + 'D'"
        v-if="trainingDuration === 3"
      />
      <TwoYearsTrainingForm
        :key="datePickerKey + 'D'"
        v-if="trainingDuration === 2"
      />
      <div class="my-11">
        <ButtonsCard
          @save-page="startSavingPlanInLocalStorageProzess"
          @reset-page="isResetDialog = true"
          @download="validateForms"
          @import-json="getTrainingDataFromJson"
          @error-import-duration="isImportErrorDialog = true"
          @error-old-version="handleOldPlanVersion"
          :key="ButtonsCardKey + 'E'"
        />
      </div>
    </v-container>
    <RestoreFileDialog
      v-if="isRestoreFileDialog"
      @new-planning="isRestoreFileDialog = false"
    />
    <ResetDialog
      v-if="isResetDialog"
      @close-dialog="isResetDialog = false"
      @delete-plan="deleteTrainingPlan"
    />
    <ImportPlanDurationError
      v-if="isImportErrorDialog"
      @close="closeDurationErrorDialod"
    />
    <NewPlanForSameStudentErrorDialog
      v-if="isSameStudentDifferentDurationsErrorDialog"
      @close="closeSameStudentDifferentDurationsErrorDialog"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import GlobalComputed from "@/helpers/screenSize";
import validation from "@/helpers/validation";
import TrainingOptionsCard from "@/components/form/shared/TrainingOptionsCard";
import StudentFormComponent from "@/components/form/StudentForm";
import ButtonsCard from "@/components/form/ButtonsCard";
import ResetDialog from "@/components/form/shared/ResetDialog";
import RestoreFileDialog from "@/components/shared/RestoreFileDialog";
import ContactForm from "@/components/form/shared/ContactForm";
import ImportPlanDurationError from "@/components/shared/ImportPlanDurationError";
import ThreeYearsTrainingForm from "@/components/form/practicalAssignments/ThreeYearsTrainingForm";
import TwoYearsTrainingForm from "@/components/form/practicalAssignments/TwoYearsTrainingForm";
import NewPlanForSameStudentErrorDialog from "@/components/form/shared/NewPlanForSameStudentErrorDialog";

export default {
  components: {
    StudentFormComponent,
    TrainingOptionsCard,
    ButtonsCard,
    RestoreFileDialog,
    ResetDialog,
    ContactForm,
    ImportPlanDurationError,
    ThreeYearsTrainingForm,
    TwoYearsTrainingForm,
    NewPlanForSameStudentErrorDialog,
  },

  data() {
    return {
      isResetDialog: false,
      isImportErrorDialog: false,
      isSameStudentDifferentDurationsErrorDialog: false,
      ButtonsCardKey: 0,
      studentComponentKey: 1,
      companyComponentKey: 2,
      optionsComponentKey: 3,
      isRestoreFileDialog: false,
      foundSavedPlan: null,
      otherDurationFoundPlan: null,
      isPlanToSaveHasOtherDuration: false,
    };
  },
  computed: {
    ...GlobalComputed,
    ...validation,
    ...mapGetters("TrainingModules", {
      getCurrentTrainingModules: "getCurrentTrainingModules",
      getCompanyData: "getCompanyData",
      getStudentData: "getStudentData",
      getCurrentSavedPlans: "getCurrentSavedPlans",
      getOtherDurationSavedPlans: "getOtherDurationSavedPlans",
      getCurrentSavedPlanName: "getCurrentSavedPlanName",
    }),
    ...mapGetters("TrainingSettings", {
      getBeginnMonth: "getBeginnMonth",
      getBeginnYear: "getBeginnYear",
      getWorkingHours: "getWorkingHours",
      getTwoYearsTreatmentArea: "getTwoYearsTreatmentArea",
      getTrainingDuration: "getTrainingDuration",
      getDatePickerComponentKey: "getDatePickerComponentKey",
      getIsErrorDuringPlanning: "getIsErrorDuringPlanning",
    }),
    trainingDuration() {
      return this.getTrainingDuration;
    },
    datePickerKey() {
      return this.getDatePickerComponentKey;
    },
    isErrorDuringPlanning() {
      return this.getIsErrorDuringPlanning;
    },
  },
  watch: {},
  async created() {
    this.getTrainingDurationFromSessionStorage();
  },
  methods: {
    ...mapActions("TrainingModules", {
      checkLocalStorage: "checkLocalStorage",
    }),
    ...mapMutations("TrainingModules", {
      setCompanyData: "setCompanyData",
      setStudentData: "setStudentData",
      setCurrentTrainingModules: "setCurrentTrainingModules",
      setCurrentSavedPlanName: "setCurrentSavedPlanName",
      setCurrentTrainingModulesFromSavedPlan:
        "setCurrentTrainingModulesFromSavedPlan",
      removeSavedPlan: "removeSavedPlan",
    }),
    ...mapMutations("TrainingSettings", {
      setTrainingBeginnMonth: "setTrainingBeginnMonth",
      setTrainingBeginnYear: "setTrainingBeginnYear",
      setTrainingBeginnDate: "setTrainingBeginnDate",
      setDateOfBeginnSecondTrainingHalf: "setDateOfBeginnSecondTrainingHalf",
      setTrainingWorkingHours: "setTrainingWorkingHours",
      setDatePickerComponentKey: "setDatePickerComponentKey",
      setTrainingDuration: "setTrainingDuration",
    }),

    getTrainingDurationFromSessionStorage() {
      if (sessionStorage.getItem("training-duration") === null) {
        this.setCurrentTrainingModules({ trainingDuration: 3, item: null });
        sessionStorage.setItem("training-duration", 3);
      } else {
        const duration = parseInt(sessionStorage.getItem("training-duration"));
        this.setTrainingDuration(duration);
        if (duration === 3) {
          this.setCurrentTrainingModules({
            trainingDuration: 3,
            item: null,
          });
        } else {
          this.setCurrentTrainingModules({
            trainingDuration: 2,
            item: "GPA AP",
          });
        }
      }
      this.checkLocalStorage(this.trainingDuration);
    },

    startSavingPlanInLocalStorageProzess() {
      this.isPlanToSaveHasOtherDuration = false;
      if (!this.checkIfSavingPlan()) return;

      this.savePlanInLocalStorage();
      this.checkLocalStorage(this.trainingDuration);
      this.setCurrentSavedPlanName({
        name: `${this.getStudentData.firstname} ${this.getStudentData.lastname}`,
        value: `${this.getStudentData.firstname} ${this.getStudentData.lastname}`,
      });
    },

    savePlanInLocalStorage() {
      let jsonObject = {
        trainingDuration: this.trainingDuration,
        beginTrainingMonth: this.getBeginnMonth,
        beginTrainingYear: this.getBeginnYear,
        workingHours: this.getWorkingHours,
        company: this.getCompanyData,
        student: this.getStudentData,
        modules: this.getCurrentTrainingModules,
      };

      if (this.getTrainingDuration === 2) {
        jsonObject.trainingType = this.getTwoYearsTreatmentArea;
      }

      jsonObject = JSON.stringify(jsonObject);
      localStorage.setItem(
        `${this.getStudentData.firstname} ${this.getStudentData.lastname}`,
        jsonObject
      );

      this.$noty.success(
        `Der Ausbildungsplan für ${this.getStudentData.firstname} ${this.getStudentData.lastname} wurde für Sie im Hintergrund gespeichert.`
      );
    },

    checkIfSavingPlan() {
      this.$refs.studentForm.validateForm();
      if (
        this.getStudentData.firstname === "" ||
        this.getStudentData.lastname === ""
      ) {
        return false;
      }

      this.otherDurationFoundPlan = this.getOtherDurationSavedPlans.find(
        (otherPlan) =>
          otherPlan.student.firstname === this.getStudentData.firstname &&
          otherPlan.student.lastname === this.getStudentData.lastname
      );

      if (
        this.otherDurationFoundPlan !== undefined &&
        this.otherDurationFoundPlan.trainingDuration !== this.trainingDuration
      ) {
        this.isSameStudentDifferentDurationsErrorDialog = true;
        this.isPlanToSaveHasOtherDuration = true;
        return false;
      } else {
        return true;
      }
    },

    validateForms() {
      const isStudentFormValid = this.$refs.studentForm.validateForm();
      const isCompanyFormValid = this.$refs.companyForm.validateForm();

      if (isStudentFormValid && isCompanyFormValid) {
        this.validateModules();
      }
    },

    validateModules() {
      if (!validation.validate(this.getCurrentTrainingModules)) return;
      this.finishPlanAndDownloadJsonFile();
    },

    // Download Json file =====================================================>
    finishPlanAndDownloadJsonFile() {
      let jsonObject = {
        trainingDuration: this.trainingDuration,
        beginTrainingMonth: this.getBeginnMonth,
        beginTrainingYear: this.getBeginnYear,
        workingHours: this.getWorkingHours,
        company: this.getCompanyData,
        student: this.getStudentData,
        modules: this.getCurrentTrainingModules,
      };
      if (this.getTrainingDuration === 2) {
        jsonObject.trainingType = this.getTwoYearsTreatmentArea;
      }
      jsonObject = JSON.stringify(jsonObject);
      const a = document.createElement("a");
      const file = new Blob([jsonObject], { type: "text/plain" });
      a.href = URL.createObjectURL(file);
      a.download = `${this.getStudentData.firstname}-${this.getStudentData.lastname}-Ausbildungsplan.json`;
      a.click();
    },
    // Download Json file <=====================================================

    getDataFromLocalStorage(savedPlanStudentName) {
      if (this.isPlanToSaveHasOtherDuration) {
        return;
      }
      this.getTrainingDataFromLocalStorage(savedPlanStudentName);
    },

    async getTrainingDataFromLocalStorage(savedPlanStudentName) {
      if (savedPlanStudentName === "Neuer Plan") {
        this.resetDataForANewPlan();
        this.setCurrentSavedPlanName({
          name: savedPlanStudentName,
          value: savedPlanStudentName,
        });
        return;
      }

      this.foundSavedPlan = this.getCurrentSavedPlans.find(
        (savedPlan) =>
          `${savedPlan.student.firstname} ${savedPlan.student.lastname}` ===
          savedPlanStudentName
      );

      await this.setTrainingWorkingHours(this.foundSavedPlan.workingHours);
      await this.setTrainingBeginnYear(this.foundSavedPlan.beginTrainingYear);
      await this.setTrainingBeginnMonth(this.foundSavedPlan.beginTrainingMonth);
      await this.setTrainingBeginnDate();
      await this.setDateOfBeginnSecondTrainingHalf();
      await this.setCompanyData(this.foundSavedPlan.company);
      await this.setStudentData(this.foundSavedPlan.student);
      await this.setCurrentTrainingModulesFromSavedPlan({
        trainingModules: this.foundSavedPlan.modules,
        trainingDuration: this.trainingDuration,
      });
      this.checkLocalStorage(this.trainingDuration);
      this.setCurrentSavedPlanName({
        name: savedPlanStudentName,
        value: savedPlanStudentName,
      });
      this.setDatePickerComponentKey();

      this.optionsComponentKey++;
      this.studentComponentKey++;
      this.companyComponentKey++;
    },

    async getTrainingDataFromJson(trainingData) {
      await this.setTrainingWorkingHours(trainingData.workingHours);
      await this.setTrainingBeginnYear(trainingData.beginTrainingYear);
      await this.setTrainingBeginnMonth(trainingData.beginTrainingMonth);
      await this.setTrainingBeginnDate();
      await this.setDateOfBeginnSecondTrainingHalf();
      await this.setCompanyData(trainingData.company);
      await this.setStudentData(trainingData.student);
      await this.setCurrentTrainingModulesFromSavedPlan({
        trainingModules: trainingData.modules,
      });
      this.setCurrentSavedPlanName({ name: "Neuer Plan", value: "Neuer Plan" });
      this.setDatePickerComponentKey();
      this.optionsComponentKey++;
      this.studentComponentKey++;
      this.companyComponentKey++;
    },

    async resetDataForANewPlan() {
      await this.setTrainingWorkingHours(8);
      this.setTrainingBeginnMonth(null);
      this.setTrainingBeginnYear(null);
      this.setTrainingBeginnDate();
      this.setDateOfBeginnSecondTrainingHalf();
      await this.setCompanyData(null);
      await this.setStudentData(null);
      await this.setTrainingDuration(
        parseInt(sessionStorage.getItem("training-duration"))
      );
      await this.setCurrentTrainingModules({
        trainingDuration: this.trainingDuration,
        item: null,
      });
      this.optionsComponentKey++;
      this.studentComponentKey++;
      this.companyComponentKey++;
      this.setDatePickerComponentKey();
      this.foundSavedPlan = null;
    },

    deleteTrainingPlan() {
      this.isResetDialog = false;
      if (this.foundSavedPlan !== null) {
        localStorage.removeItem(
          `${this.foundSavedPlan.student.firstname} ${this.foundSavedPlan.student.lastname}`
        );
      }
      location.reload();
    },

    closeDurationErrorDialod() {
      this.isImportErrorDialog = false;
      this.ButtonsCardKey++;
    },

    handleOldPlanVersion() {
      this.$noty.error(
        "Dieser Ausbildungsplan ist leider nicht kompatibel mit der aktuellen Version des Planungssystems."
      );
      this.ButtonsCardKey++;
    },

    closeSameStudentDifferentDurationsErrorDialog(closeValue) {
      if (closeValue === "ja") {
        this.removeSavedPlan(this.otherDurationFoundPlan);
        this.savePlanInLocalStorage();
        this.checkLocalStorage(this.trainingDuration);
        this.setCurrentSavedPlanName({
          name: `${this.getStudentData.firstname} ${this.getStudentData.lastname}`,
          value: `${this.getStudentData.firstname} ${this.getStudentData.lastname}`,
        });
      } else {
        this.setCurrentSavedPlanName({
          name: this.getCurrentSavedPlanName.name,
          value: this.getCurrentSavedPlanName.name,
        });
      }
      this.isSameStudentDifferentDurationsErrorDialog = false;
    },
  },
};
</script>

<style>
#start {
  height: 100%;
  background-color: #f2f2f2 !important;
  margin-bottom: 15px;
}
.v-select__selections {
  font-size: 15px !important;
}
.big-screen-container {
  width: 45% !important;
}
.small-screen-container {
  width: 55% !important;
}
</style>
