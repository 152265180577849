let orts = [
  { district: "Allermöhe", postCode: ["21035", "21037"] },
  { district: "Alsterdorf", postCode: ["20251", "22297", "22335"] },
  { district: "Altenwerder", postCode: ["21129"] },
  {
    district: "Altona-Altstadt",
    postCode: ["20359", "22765", "22767", "22769"],
  },
  {
    district: "Altona-Nord",
    postCode: ["20257", "20357", "22765", "22769"],
  },
  {
    district: "Bahrenfeld",
    postCode: ["22525", "22547", "22549", "22607", "22761", "22769", "22605"],
  },
  {
    district: "Barmbek-Nord",
    postCode: ["22297", "22305", "22307", "22309", "22303"],
  },
  {
    district: "Barmbek-Süd",
    postCode: ["22081", "22083", "22085", "22305"],
  },
  { district: "Bergedorf", postCode: ["21033", "21035", "21029", "21031"] },
  { district: "Bergstedt", postCode: ["22359", "22395"] },
  { district: "Billstedt", postCode: ["22111", "22115", "22117", "22119"] },
  { district: "Bilwerder", postCode: ["21033", "21035"] },
  { district: "Blankenese", postCode: ["22587", "22589"] },
  { district: "Borgfelde", postCode: ["20535", "20537"] },
  {
    district: "Bramfeld",
    postCode: [
      "22047",
      "22159",
      "22175",
      "22179",
      "22309",
      "22391",
      "22393",
      "22177",
    ],
  },
  { district: "Cranz", postCode: ["21129"] },
  { district: "Curslack", postCode: ["21037"] },
  { district: "Dulsberg", postCode: ["22049"] },
  { district: "Duvenstedt", postCode: ["22397"] },
  {
    district: "Eidelstedt",
    postCode: ["22457", "22523", "22525", "22527", "22547"],
  },
  { district: "Eilbek", postCode: ["22089"] },
  {
    district: "Eimsbüttel",
    postCode: [
      "20144",
      "20253",
      "20255",
      "20257",
      "20259",
      "20357",
      "22525",
      "22527",
      "22769",
    ],
  },
  { district: "Eppendorf", postCode: ["20249", "20251", "22529"] },
  { district: "Eißendorf", postCode: ["21075", "21077", "21073"] },
  { district: "Farmsen-Berne", postCode: ["22159"] },
  { district: "Finkenwerder", postCode: ["21129"] },
  { district: "Francop", postCode: ["21129"] },
  { district: "Fuhlsbüttel", postCode: ["22339", "22415", "22453", "22335"] },
  { district: "Groß Borstel", postCode: ["22297", "22453", "22529", "22335"] },
  { district: "Groß Flottbek", postCode: ["22607", "22609", "22605"] },
  { district: "Gut Moor", postCode: ["21079"] },
  { district: "HafenCity", postCode: ["20457", "20539"] },
  {
    district: "Hamburg-Altstadt",
    postCode: ["20095", "20099", "20457", "20459"],
  },
  {
    district: "Hamm",
    postCode: ["20097", "20535", "20537", "22087", "22089", "22111"],
  },
  { district: "Hammerbrook", postCode: ["20095", "20097", "20537"] },
  { district: "Harburg", postCode: ["21075", "21079", "21073"] },
  {
    district: "Harvestehude",
    postCode: ["20144", "20146", "20148", "20149", "20249", "20253"],
  },
  { district: "Hausbruch", postCode: ["21075", "21079", "21147", "21149"] },
  { district: "Heimfeld", postCode: ["21075", "21079", "21073"] },
  {
    district: "Hoheluft-Ost",
    postCode: ["20144", "20249", "20251", "20253"],
  },
  { district: "Hoheluft-West", postCode: ["20253", "20255", "22529"] },
  { district: "Hohenfelde", postCode: ["22087", "22089"] },
  { district: "Horn", postCode: ["22111", "22119"] },
  {
    district: "Hummelsbüttel",
    postCode: ["22339", "22391", "22399", "22415", "22417"],
  },
  { district: "Iserbrook", postCode: ["22589"] },
  { district: "Jenfeld", postCode: ["22043", "22045"] },
  { district: "Kirchwerder", postCode: ["21037"] },
  { district: "Kleiner Grasbrook", postCode: ["20457", "20539"] },
  { district: "Langenbek", postCode: ["21077", "21079"] },
  { district: "Langenhorn", postCode: ["22415", "22417", "22419"] },
  { district: "Lemsahl-Mellingstedt", postCode: ["22397", "22399"] },
  { district: "Lohbrügge", postCode: ["21033", "22115", "21031"] },
  { district: "Lokstedt", postCode: ["20253", "20255", "22527", "22529"] },
  { district: "Lurup", postCode: ["22525", "22547", "22549"] },
  { district: "Marienthal", postCode: ["22041", "22043", "22089"] },
  { district: "Marmstorf", postCode: ["21077"] },
  { district: "Moorburg", postCode: ["21079", "21129"] },
  { district: "Neuallermöhe", postCode: ["21035"] },
  { district: "Neuenfelde", postCode: ["21129"] },
  { district: "Neuengamme", postCode: ["21037"] },
  { district: "Neugraben-Fischbek", postCode: ["21147", "21149"] },
  { district: "Neuland", postCode: ["21079"] },
  {
    district: "Neustadt",
    postCode: ["20354", "20355", "20359", "20457", "20459"],
  },
  {
    district: "Niendorf",
    postCode: ["22453", "22455", "22457", "22459", "22529"],
  },
  { district: "Nienstedten", postCode: ["22587", "22607", "22609"] },
  { district: "Ochsenwerder", postCode: ["21037"] },
  { district: "Ohlsdorf", postCode: ["22309", "22391", "22335"] },
  { district: "Osdorf", postCode: ["22549", "22587", "22609", "22589"] },
  { district: "Othmarschen", postCode: ["22607", "22609", "22763", "22605"] },
  { district: "Ottensen", postCode: ["22763", "22765", "22767"] },
  {
    district: "Poppenbüttel",
    postCode: ["22391", "22393", "22395", "22399"],
  },
  { district: "Rahlstedt", postCode: ["22143", "22147", "22149", "22359"] },
  { district: "Reitbrook", postCode: ["21037"] },
  { district: "Rissen", postCode: ["22559", "22587"] },
  { district: "Rönneburg", postCode: ["21077", "21079"] },
  { district: "Rothenburgsort", postCode: ["20539"] },
  {
    district: "Rotherbaum",
    postCode: ["20144", "20146", "20148", "20149", "20354", "20357"],
  },
  { district: "Sasel", postCode: ["22159", "22391", "22393", "22395"] },
  { district: "Schnelsen", postCode: ["22455", "22457", "22459"] },
  { district: "Sinstorf", postCode: ["21077", "21079"] },
  { district: "Spadenland", postCode: ["21037"] },
  { district: "St. Georg", postCode: ["20095", "20097", "20099"] },
  {
    district: "St. Pauli",
    postCode: ["20354", "20355", "20357", "20359", "20459", "22767", "22769"],
  },
  {
    district: "Steilshoop",
    postCode: ["22309", "22177"],
  },
  { district: "Steinwerder", postCode: ["20457", "21107"] },
  {
    district: "Stellingen",
    postCode: ["20255", "22525", "22527", "22529", "22769"],
  },
  {
    district: "Sternschanze",
    postCode: ["20357", "20359", "22767", "22769"],
  },
  { district: "Sülldorf", postCode: ["22587", "22589"] },
  { district: "Tatenberg", postCode: ["21037"] },
  {
    district: "Tonndorf",
    postCode: ["22041", "22043", "22045", "22047", "22149", "22159"],
  },
  { district: "Uhlenhorst", postCode: ["22081", "22085", "22087"] },
  { district: "Veddel", postCode: ["20539", "21109"] },
  { district: "Volksdorf", postCode: ["22359"] },
  { district: "Waltershof", postCode: ["21129"] },
  { district: "Wandsbek", postCode: ["22041", "22047", "22049", "22089"] },
  { district: "Wellingsbüttel", postCode: ["22391", "22393"] },
  { district: "Wilhelmsburg", postCode: ["20539", "21109", "21107"] },
  { district: "Wilstorf", postCode: ["21077", "21079", "21073"] },
  {
    district: "Winterhude",
    postCode: ["20249", "22297", "22299", "22301", "22305", "22303"],
  },
  { district: "Wohldorf-Ohlstedt", postCode: ["22395", "22397"] },
];
export default {
  getFoundDistrict(postCode) {
    return orts.filter((ort) => ort.postCode.find((item) => item === postCode));
  },
};
