<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="white--text text-h5 lighten-2"
          style="background-color: #4fb6e3"
        >
          Über die Seite
        </v-card-title>

        <v-card-text class="mt-4">
          Diese Seite dient als Grundlage zur Erstellung von Ausbildungsplänen
          im Rahmen der Generalistischen Pflegeausbildung (GeP) für die mit der
          <br />
          BS 12 kooperierenden Betriebe.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#4FB6E3" text @click="$emit('close')"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
