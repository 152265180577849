<template>
  <v-footer
    width="100vw"
    flat
    color="rgba(0, 0, 0, 0.0)"
    class="footer justify-center pb-3 pt-1 mt-10"
  >
    <div class="footer-right">
      <a
        class="footer-content mr-9"
        href="https://www.berufliche-schule-burgstrasse.de/datenschutz/"
        target="_blank"
      >
        Datenschutz</a
      >
      <a
        class="footer-content"
        href="https://www.berufliche-schule-burgstrasse.de/impressum/"
        target="_blank"
      >
        Impressum</a
      >
    </div>
  </v-footer>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  right: 0px;
  display: flex;
  color: #919191 !important;
}
.footer-content {
  font-size: 16px;
}
a {
  color: #919191 !important;
  text-decoration: none;
}
</style>
