export default {
  data() {
    return {};
  },
  methods: {
    checkRange(modules, dates) {
      let isDateAlreadyPlanned = false;
      let allPlannedRanges = [];

      for (let i = 0; i < modules.length; i++) {
        let trainingModule = modules[i];

        if (trainingModule.name !== "Urlaub") {
          allPlannedRanges = [
            ...trainingModule.dayShiftRanges,
            ...trainingModule.nightShiftRanges,
          ];
        }

        if (
          allPlannedRanges.find(
            (date) => dates[0] < date.start && dates[1] > date.end
          )
        ) {
          this.$noty.error(
            "Es dürfen nicht unterschiedliche Praxiseinsätze zur selben Zeit geplant werden."
          );
          isDateAlreadyPlanned = true;
          break;
        }
      }
      return isDateAlreadyPlanned;
    },

    getIsDoneStatus(item) {
      return (
        item.plannedHours >= item.totalHours &&
        item.trainingCompany.houseNumber !== "" &&
        item.trainingCompany.name !== "" &&
        item.trainingCompany.place !== "" &&
        item.trainingCompany.postCode !== "" &&
        item.trainingCompany.street !== "" &&
        item.trainingCompany.contactPerson !== "" &&
        item.trainingCompany.contactPersonGender !== "" &&
        item.trainingCompany.telephone !== "" &&
        item.trainingCompany.email !== ""
      );
    },

    getIsHoursNotice(item) {
      return (
        item.plannedHours === 0 &&
        item.trainingCompany.houseNumber === "" &&
        item.trainingCompany.place === "" &&
        item.trainingCompany.name === "" &&
        item.trainingCompany.postCode === "" &&
        item.trainingCompany.street === "" &&
        item.trainingCompany.contactPerson === "" &&
        item.trainingCompany.contactPersonGender === "" &&
        item.trainingCompany.telephone === "" &&
        item.trainingCompany.email === ""
      );
    },

    checkNumberOfCheckedModules() {
      return this.modules.filter(
        (item) =>
          item.plannedHours >= item.totalHours &&
          item.trainingCompany.houseNumber !== "" &&
          item.trainingCompany.place !== "" &&
          item.trainingCompany.postCode !== "" &&
          item.trainingCompany.street !== "" &&
          item.trainingCompany.contactPerson !== "" &&
          item.trainingCompany.contactPersonGender !== "" &&
          item.trainingCompany.telephone !== "" &&
          item.trainingCompany.email !== ""
      ).length;
    },

    getChipText(firstDate, secondDate) {
      return `${firstDate.split("-").reverse().join(".")} bis ${secondDate
        .split("-")
        .reverse()
        .join(".")}`;
    },

    getThreeYearsSelectedModuleInfoText(currentModule) {
      if (currentModule.name === "Urlaub") {
        return "30 Urlaubstage pro Kalenderjahr.";
      } else if (
        currentModule.trainingYear === 1 &&
        currentModule.name === "Pflichteinsatz Pädiatrische Versorgung"
      ) {
        return "Dieser Praxiseinsatz muss bis Ende des 2. Ausbildungsjahres abschlossen sein. Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen.";
      } else if (
        currentModule.trainingYear === 1 &&
        currentModule.name !== "Pflichteinsatz Pädiatrische Versorgung"
      ) {
        return "Dieser Praxiseinsatz muss bis Ende des 2. Ausbildungsjahres abschlossen sein.";
      } else if (
        currentModule.trainingYear === 2 &&
        currentModule.name === "Pflichteinsatz Psychiatrische Versorgung"
      ) {
        return "Dieser Praxiseinsatz kann erst im 3. Ausbildungsdrittel geplant werden. Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen.";
      } else {
        return "Dieser Praxiseinsatz kann erst im 3. Ausbildungsdrittel geplant werden.";
      }
    },

    getTwoYearsSelectedModuleInfoText(currentModule) {
      if (
        currentModule.trainingYear === 1 &&
        currentModule.name === "Pflichteinsatz Pädiatrische Versorgung"
      ) {
        return "Dieser Praxiseinsatz muss bis Ende des 1. Ausbildungsjahres abschlossen sein. Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen.";
      } else if (
        currentModule.trainingYear === 1 &&
        currentModule.name !== "Pflichteinsatz Pädiatrische Versorgung"
      ) {
        return "Dieser Praxiseinsatz muss vor Ende des 1. Ausbildungsjahres abschlossen sein.";
      } else if (
        currentModule.trainingYear === 2 &&
        currentModule.name === "Pflichteinsatz Psychiatrische Versorgung"
      ) {
        return "Dieser Praxiseinsatz kann erst im 2. Ausbildungsjahr geplant werden. Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen.";
      } else {
        return "Dieser Praxiseinsatz kann erst im 2. Ausbildungsjahr geplant werden.";
      }
    },

    updateModuleData(
      currentModule,
      isNightShift,
      dates,
      rangeHoures,
      nightShiftHours,
      dateRangeText
    ) {
      let moduleToUpdate = { ...currentModule };
      let newRangeTexts = [...dateRangeText];

      moduleToUpdate.plannedHours += rangeHoures;

      if (
        moduleToUpdate.plannedHours % 1 != 0 &&
        moduleToUpdate.plannedHours.toString().split(".")[1].length > 2
      ) {
        moduleToUpdate.plannedHours = parseFloat(
          moduleToUpdate.plannedHours
        ).toFixed(2);
        moduleToUpdate.plannedHours = parseFloat(moduleToUpdate.plannedHours);
      }

      if (
        moduleToUpdate.name.includes("Pädiatrische Versorgung") &&
        moduleToUpdate.plannedHours > 120
      ) {
        moduleToUpdate.plannedHours = 120;
      }

      if (isNightShift) {
        moduleToUpdate.nightShiftRanges.push({
          start: dates[0],
          end: dates[1],
          plannedRangeHours: rangeHoures,
        });

        if (nightShiftHours >= 120) {
          isNightShift = false;
        }
      } else {
        moduleToUpdate.dayShiftRanges.push({
          start: dates[0],
          end: dates[1],
          plannedRangeHours: rangeHoures,
        });
      }

      newRangeTexts.push({
        text: this.getChipText(dates[0], dates[1]),
        range: dates,
      });

      return [moduleToUpdate, newRangeTexts];
    },

    getFormattedDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
    },

    createChips(val) {
      const chips = [];
      let allModuleRanges = [...val.dayShiftRanges, ...val.nightShiftRanges];

      if (allModuleRanges.length > 0) {
        allModuleRanges.forEach((range) => {
          chips.push({
            text: this.getChipText(range.start, range.end),
            range: [range.start, range.end],
          });
        });
      }
      return chips;
    },
  },
};
