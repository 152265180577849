<template>
  <div id="form">
    <v-card class="px-6" :style="mobileOnly ? 'width: 100%' : ''">
      <h1 class="pt-8">Praxiseinsätze für die drei jährige Ausbildung</h1>
      <v-form>
        <InputSelect
          :modules="modules"
          :currentModule="currentModule"
          :isThreeYearsTraining="true"
          @change-selected-module="handleChangeModule($event)"
          :key="selectInputKey"
        />

        <v-card flat class="px-2 mt-0" style="border: 1px solid #4fb6e3">
          <h2
            :class="[
              currentModule.name === 'Urlaub' ? 'grey--text' : '',
              'pt-8',
            ]"
          >
            Über den Betrieb für den Praxiseinsatz
          </h2>
          <ContactForm
            class="pt-2"
            :key="contactComponentKey"
            :data="currentModule.trainingCompany"
            @company-data="updateModuleTrainingCompany($event)"
            :is-disabled="currentModule.name === 'Urlaub'"
            ref="contactForm"
          />
        </v-card>

        <RangesChips
          :dateRangeText="dateRangeText"
          :currentModule="currentModule"
          :isThreeYearsTraining="true"
          :isDisableCalendar="isDisableCalendar"
          :isNoHoursPlanned="isNoHoursPlanned"
          :beginnMonth="beginnMonth"
          :beginnYear="beginnYear"
          :isNightShiftProp="isNightShift"
          :actualPlannedNightShiftDates="actualPlannedNightShiftDates"
          :isNightShiftSwitcherDisabled="isNightShiftSwitcherDisabled"
          @toggle-night-shift="($event) => (isNightShift = $event)"
          @toggle-remove-range="
            ($event) => removeDateRange($event.item, $event.index)
          "
        />

        <!-- Date picker -->
        <v-row>
          <v-col cols="12">
            <v-date-picker
              v-model="datesModel"
              :events="functionEvents"
              class="ml-0"
              :selected-items-text="selectedDatesTitle"
              range
              full-width
              :first-day-of-week="1"
              :allowed-dates="getAllowedDays"
              locale="de-de"
              :disabled="!isDisableCalendar"
              reactive
              header-color="#4FB6E3"
              @click:year="handleChangeDatePickerValue"
              @click:month="handleChangeDatePickerValue"
            />
          </v-col>
        </v-row>

        <!-- Hint for the colors in date picker -->
        <CalanderLabelsHint />
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ScreenSizeComputed from "@/helpers/screenSize";
import placesComputed from "@/helpers/places";
import datePickerFunctions from "@/helpers/threeYearsDatePicker";
import calculationFunctions from "@/helpers/threeYearsDatesCalculation";
import examsDates from "@/helpers/examsDates";
import validation from "@/helpers/validation";
import ClassesAndBlocks from "@/helpers/classesAndBlocks";
import ContactForm from "@/components/form/shared/ContactForm";
import datePickerMixin from "@/mixins/datePickerMixin";
import splitRangeMixin from "@/mixins/splitRangeMixin";
import InputSelect from "@/components/form/shared/InputSelect";
import RangesChips from "@/components/form/shared/RangesChips";
import CalanderLabelsHint from "@/components/form/shared/CalanderLabelsHint";

export default {
  mixins: [datePickerMixin, splitRangeMixin],
  components: {
    ContactForm,
    InputSelect,
    RangesChips,
    CalanderLabelsHint,
  },
  data() {
    return {
      selectInputKey: 0,
      nightShiftHours: 0,
      isShowNightShiftTooltip: false,
      isNightShift: false,
      isNightShiftOutOfAllowedRange: false,
      isUpdateStateModule: true,
      todayDate: null,
      currentYear: "",
      dates: [],
      dateRangeText: [],
      rangeHoures: 0,
      actualPlannedDayShiftDates: [],
      actualPlannedNightShiftDates: [],
      actualPlannedVacationDates: [],
      tempoVacationDays: [],
      selectedDatesTitle: null,
      contactComponentKey: 2,
      isReturnSingleDate: true,
      singleDateArray: [],
      currentModule: {},
    };
  },

  computed: {
    ...ScreenSizeComputed,
    ...placesComputed,
    ...datePickerFunctions,
    ...calculationFunctions,
    ...ClassesAndBlocks,
    ...examsDates,
    ...validation,

    ...mapGetters("App", {
      getSchoolHolidays: "getSchoolHolidays",
      getFeasts: "getFeasts",
    }),

    ...mapGetters("TrainingSettings", {
      getBeginnYear: "getBeginnYear",
      getBeginnMonth: "getBeginnMonth",
      getTrainingBeginnDate: "getTrainingBeginnDate",
      getDateOfBeginnSecondTrainingHalf: "getDateOfBeginnSecondTrainingHalf",
      getWorkingHours: "getWorkingHours",
      getBlocks: "getBlocks",
      getFirstMidtermExamDates: "getFirstMidtermExamDates",
      getSecondMidtermExamDates: "getSecondMidtermExamDates",
      getFinalExamDates: "getFinalExamDates",
    }),

    ...mapGetters("TrainingModules", {
      getCurrentTrainingModules: "getCurrentTrainingModules",
      getCurrentModule: "getCurrentModule",
    }),

    modules() {
      return this.getCurrentTrainingModules;
    },

    beginnMonth() {
      return this.getBeginnMonth;
    },

    beginnYear() {
      return this.getBeginnYear;
    },

    trainingBeginnDate() {
      return this.getTrainingBeginnDate;
    },

    trainingEndDate() {
      return this.finalExamDates[this.finalExamDates.length - 1];
    },

    dateOfBeginnSecondTrainingHalf() {
      return this.getDateOfBeginnSecondTrainingHalf;
    },

    formattedDateOfBeginnSecondTrainingHalf() {
      return (
        this.dateOfBeginnSecondTrainingHalf.slice(5, 7) +
        "." +
        this.dateOfBeginnSecondTrainingHalf.slice(0, 4)
      );
    },

    workingHours() {
      return this.getWorkingHours;
    },

    schoolHolidays() {
      return this.getSchoolHolidays;
    },

    blocks() {
      return this.getBlocks;
    },

    firstMidtermExamDates() {
      return this.getFirstMidtermExamDates;
    },

    secondMidtermExamDates() {
      return this.getSecondMidtermExamDates;
    },

    finalExamDates() {
      return this.getFinalExamDates;
    },

    isDisableCalendar() {
      return (
        this.beginnMonth !== null &&
        this.beginnYear !== null &&
        this.workingHours !== ""
      );
    },

    isNoHoursPlanned() {
      return !this.modules.find((modul) => modul.plannedHours > 0);
    },

    datesModel: {
      get() {
        if (this.isReturnSingleDate) {
          return this.singleDateArray;
        } else {
          return this.dates;
        }
      },
      set(newValue) {
        if (this.isReturnSingleDate) {
          this.dates = [];
          this.dates.push(newValue[1]);
          this.isReturnSingleDate = false;
        } else {
          this.dates = newValue;
          if (this.dates.length === 2) {
            this.handlePickedDates();
          }
        }
      },
    },

    isNightShiftSwitcherDisabled() {
      return (
        this.currentModule.name === "Urlaub" ||
        (this.currentModule.name === "Pflichteinsatz Pädiatrische Versorgung" &&
          this.currentModule.plannedHours >= 120) ||
        this.nightShiftHours >= 120 ||
        this.isNightShiftOutOfAllowedRange
      );
    },

    getFixedNightShiftHours() {
      if (120 - this.nightShiftHours < 0) {
        return 0;
      } else if (this.nightShiftHours % 1 !== 0) {
        return (120 - this.nightShiftHours).toFixed(1);
      } else {
        return this.nightShiftHours;
      }
    },
  },

  watch: {
    currentModule(val) {
      this.dateRangeText = this.createChips(val);
    },
  },

  async created() {
    this.setCurrentModule(0);
    this.currentModule = JSON.parse(JSON.stringify(this.getCurrentModule));
    await this.fetchData();
    this.todayDate = new Date();
    this.todayDate = this.getFormattedDate(this.todayDate);
    await this.singleDateArray.push(this.todayDate);
    this.checkIfModulesAlreadyPlanned();
  },

  methods: {
    ...mapActions("App", { fetchVacations: "fetchVacations" }),
    ...mapMutations("TrainingModules", {
      setCurrentModule: "setCurrentModule",
      updateStateModule: "updateStateModule",
      setNumberOfPlannedModules: "setNumberOfPlannedModules",
    }),

    updateModuleTrainingCompany(companyData) {
      this.currentModule.trainingCompany = JSON.parse(
        JSON.stringify(companyData)
      );
      this.updateStateModule(this.currentModule);
      this.setNumberOfPlannedModules(this.checkNumberOfCheckedModules());
      //TODO Set this in the global store instead of reload the component every time
      this.selectInputKey++;
    },

    async fetchData() {
      await this.fetchVacations(this.todayDate);
    },

    handleChangeDatePickerValue(val) {
      this.currentYear = val.toString().slice(0, 4);
    },

    checkIfModulesAlreadyPlanned() {
      if (
        this.modules.find(
          (trainingModule) =>
            trainingModule.nightShiftRanges.length > 0 ||
            trainingModule.dayShiftRanges.length > 0
        )
      ) {
        // handle the vacation first. In order nicht to save the vacation
        // dates in the actualPlannedDates arrays
        const vacationModule = this.modules.find(
          (trainingModule) => trainingModule.name === "Urlaub"
        );

        if (vacationModule.dayShiftRanges.length > 0) {
          vacationModule.dayShiftRanges.forEach((dataRange) => {
            this.dates = [dataRange.start, dataRange.end];
            this.handleVacationModule();
          });
        }

        this.modules.forEach((trainingModule) => {
          if (trainingModule.nightShiftRanges.length > 0) {
            trainingModule.nightShiftRanges.forEach((nightShiftRange) => {
              this.dates = [nightShiftRange.start, nightShiftRange.end];
              this.getDifferenceBetweenNightShiftRanges();
            });
          }

          if (trainingModule.dayShiftRanges.length > 0) {
            trainingModule.dayShiftRanges.forEach((dataRange) => {
              this.dates = [dataRange.start, dataRange.end];
              if (trainingModule.name !== "Urlaub") {
                this.getDifferenceBetweenDayShiftRanges();
              }
            });
          }
        });
        this.setNumberOfPlannedModules(this.checkNumberOfCheckedModules());
        this.selectInputKey++;
      }
    },

    handlePickedDates() {
      if (this.dates === null || this.dates.length < 2) return;

      if (this.dates[0] > this.dates[1]) {
        this.$noty.error("Enddatum darf nicht vor dem Startdatum liegen!");
        this.dates = null;
        this.isReturnSingleDate = true;
        return;
      }

      this.selectedDatesTitle = this.getChipText(this.dates[0], this.dates[1]);
      this.checkIfPickedRangeAlreadyPlanned();
    },

    functionEvents(dayDate) {
      if (this.currentYear !== dayDate.slice(0, 4)) {
        this.currentYear = dayDate.slice(0, 4);
      }

      return datePickerFunctions.daysColor(
        dayDate,
        this.schoolHolidays,
        this.getFeasts,
        this.actualPlannedDayShiftDates,
        this.actualPlannedNightShiftDates,
        this.firstMidtermExamDates,
        this.blocks,
        this.finalExamDates,
        this.actualPlannedVacationDates
      );
    },

    getAllowedDays(date) {
      if (
        date < this.dateOfBeginnSecondTrainingHalf ||
        date > this.trainingEndDate
      ) {
        this.isNightShiftOutOfAllowedRange = true;
        this.isNightShift = false;
      } else {
        this.isNightShiftOutOfAllowedRange = false;
      }

      if (this.currentModule.name === "Urlaub") {
        return datePickerFunctions.getIsDateAllowedForVacationModule(
          date,
          this.actualPlannedDayShiftDates,
          this.actualPlannedNightShiftDates,
          this.getFeasts,
          this.trainingBeginnDate,
          this.blocks,
          this.finalExamDates,
          this.actualPlannedVacationDates
        );
      } else {
        return datePickerFunctions.getIsDateAllowed(
          date,
          this.currentModule,
          this.firstMidtermExamDates,
          this.schoolHolidays,
          this.actualPlannedDayShiftDates,
          this.actualPlannedNightShiftDates,
          this.getFeasts,
          this.trainingBeginnDate,
          this.blocks,
          this.finalExamDates,
          this.actualPlannedVacationDates
        );
      }
    },

    getDifferenceBetweenDateRange() {
      if (this.isNightShift) {
        this.getDifferenceBetweenNightShiftRanges();
      } else {
        this.getDifferenceBetweenDayShiftRanges();
      }
    },

    getDifferenceBetweenNightShiftRanges() {
      this.handleNightShiftDates();
    },

    getDifferenceBetweenDayShiftRanges() {
      if (this.currentModule.name === "Urlaub") {
        this.handleVacationModule();
      } else if (this.currentModule.name.includes("Pädiatrische Versorgung")) {
        this.handlePaediatrischeVersorgung();
      } else if (
        this.currentModule.name.includes("Psychiatrische Versorgung")
      ) {
        this.handlePsychiatrischeVersorgung();
      } else {
        this.handleOtherModules();
      }
    },

    handleNightShiftDates() {
      const result = calculationFunctions.calculateNightShiftDates(
        this.dates,
        this.currentModule,
        this.workingHours,
        this.blocks,
        this.nightShiftHours,
        this.getFeasts,
        this.actualPlannedVacationDates
      );
      if (result[0]) {
        this.actualPlannedNightShiftDates =
          this.actualPlannedNightShiftDates.concat(result[1]);

        this.nightShiftHours += result[2];
        this.rangeHoures = result[2];

        this.isUpdateStateModule = true;
      } else {
        this.dates = null;
        this.isUpdateStateModule = false;
      }
    },

    handleVacationModule() {
      const result = calculationFunctions.calculateVacationModule(
        this.dates,
        this.currentModule,
        this.blocks,
        this.getFeasts,
        this.currentYear,
        this.actualPlannedVacationDates
      );
      if (result[0]) {
        this.tempoVacationDays = [];
        this.tempoVacationDays = this.tempoVacationDays.concat(result[1]);

        this.actualPlannedVacationDates =
          this.actualPlannedVacationDates.concat(result[1]);

        this.dates[0] = this.tempoVacationDays[0];

        this.dates[1] =
          this.tempoVacationDays[this.tempoVacationDays.length - 1];

        this.isUpdateStateModule = true;
      } else {
        this.dates = null;
        this.isUpdateStateModule = false;
      }
    },

    handlePaediatrischeVersorgung() {
      const result =
        calculationFunctions.calculatePaediatrischeVersorgungModule(
          this.dates,
          this.currentModule,
          this.workingHours,
          this.blocks,
          this.getFeasts,
          this.actualPlannedVacationDates
        );
      if (result[0]) {
        this.actualPlannedDayShiftDates =
          this.actualPlannedDayShiftDates.concat(result[1]);
        this.rangeHoures = result[2];

        this.isUpdateStateModule = true;
      } else {
        this.dates = null;
        this.isUpdateStateModule = false;
      }
    },

    handlePsychiatrischeVersorgung() {
      const result = calculationFunctions.calculatePsychiatrischeVersorgung(
        this.dates,
        // this.currentModule,
        this.workingHours,
        this.blocks,
        this.getFeasts,
        // this.finalExamDates,
        this.actualPlannedVacationDates
      );
      if (result[0]) {
        this.actualPlannedDayShiftDates =
          this.actualPlannedDayShiftDates.concat(result[1]);

        this.rangeHoures = result[2];
        this.isUpdateStateModule = true;
      } else {
        this.dates = null;
        this.isUpdateStateModule = false;
      }
    },

    handleOtherModules() {
      const result = calculationFunctions.calculateModuleRange(
        this.dates,
        this.currentModule,
        this.workingHours,
        this.blocks,
        this.getFeasts,
        // this.finalExamDates,
        this.actualPlannedVacationDates
      );
      if (result[0]) {
        this.actualPlannedDayShiftDates =
          this.actualPlannedDayShiftDates.concat(result[1]);

        this.rangeHoures = result[2];
        this.isUpdateStateModule = true;
      } else {
        this.dates = null;
        this.isUpdateStateModule = false;
      }
    },

    removeDateRange(range, rangeIndex) {
      if (this.currentModule.name === "Urlaub") {
        this.handleRemoveVacationRange(range, rangeIndex);
      } else {
        this.handleRemoveModuleRange(range, rangeIndex);
      }

      this.updateStateModule(this.currentModule);

      this.setNumberOfPlannedModules(this.checkNumberOfCheckedModules());
      this.selectInputKey++;
    },

    handleRemoveVacationRange(range, rangeIndex) {
      this.checkForPlannedModule(range);
      const deleteResult = calculationFunctions.removeVacationDateRange(
        range,
        rangeIndex,
        this.currentModule,
        this.dateRangeText,
        this.actualPlannedVacationDates
      );
      this.currentModule.dayShiftRanges = deleteResult[0].dayShiftRanges;
      this.currentModule.vacationDays = deleteResult[0].vacationDays;
      this.dateRangeText = deleteResult[1];
      this.actualPlannedVacationDates = deleteResult[2];

      this.dates = [];
      this.isReturnSingleDate = true;
    },

    handleRemoveModuleRange(range, rangeIndex) {
      const deleteResult = calculationFunctions.removeModuleDateRange(
        range,
        rangeIndex,
        this.currentModule,
        this.actualPlannedDayShiftDates,
        this.actualPlannedNightShiftDates,
        this.workingHours,
        this.dateRangeText,
        this.nightShiftHours
      );

      this.actualPlannedDayShiftDates = deleteResult[0];
      this.actualPlannedNightShiftDates = deleteResult[1];

      this.currentModule.dayShiftRanges = deleteResult[2].dayShiftRanges;
      this.currentModule.nightShiftRanges = deleteResult[2].nightShiftRanges;
      this.currentModule.plannedHours = deleteResult[2].plannedHours;
      this.dateRangeText = deleteResult[3];

      this.nightShiftHours = deleteResult[4];
      this.dates = [];
      this.isReturnSingleDate = true;
      if (this.nightShiftHours >= 120) {
        this.isNightShift = false;
      }
    },

    checkIfPickedRangeAlreadyPlanned() {
      if (this.modules.length === 0) {
        return;
      }

      const isDateAlreadyPlanned = this.checkRange(this.modules, this.dates);

      if (isDateAlreadyPlanned) {
        this.dates = null;
      } else {
        this.getDifferenceBetweenDateRange();

        if (this.currentModule.name === "Urlaub") {
          this.updateVacationModule();
        } else {
          this.updateTrainingModule();
        }
        this.updateStateModule(this.currentModule);
      }
    },

    updateVacationModule() {
      if (!this.isUpdateStateModule) return;

      this.currentModule.dayShiftRanges.push({
        start: this.dates[0],
        end: this.dates[1],
      });

      this.currentModule.vacationDays.push(...this.tempoVacationDays);

      //TODO getChipText
      this.dateRangeText.push({
        text: this.getChipText(this.dates[0], this.dates[1]),
        range: this.dates,
      });
    },

    updateTrainingModule() {
      if (!this.isUpdateStateModule) return;

      const updateModuleResult = this.updateModuleData(
        this.currentModule,
        this.isNightShift,
        this.dates,
        this.rangeHoures,
        this.nightShiftHours,
        this.dateRangeText
      );

      this.currentModule = updateModuleResult[0];
      this.dateRangeText = updateModuleResult[1];

      if (
        this.nightShiftHours >= 120 ||
        (this.currentModule.name === "Pflichteinsatz Pädiatrische Versorgung" &&
          this.currentModule.plannedHours === 120)
      ) {
        this.isNightShift = false;
      }

      // this.updateStateModule(this.currentModule);
      this.setNumberOfPlannedModules(this.checkNumberOfCheckedModules());
      this.selectInputKey++;
    },

    handleChangeModule(module) {
      if (module.name === "Urlaub") {
        this.isNightShift = false;
      }
      const indexToFind = this.modules.indexOf(
        this.modules.find(
          (trainingModule) => trainingModule.name === module.name
        )
      );
      this.setCurrentModule(indexToFind);
      this.currentModule = JSON.parse(JSON.stringify(this.getCurrentModule));
      this.contactComponentKey++;
    },

    checkForPlannedModule(vacationRange) {
      this.checkIfModulesIncludeVacation(vacationRange);
    },
  },
};
</script>

<style>
#form {
  height: 100%;
  background-color: #f2f2f2 !important;
  margin-bottom: 15px;
}
.v-select__selections {
  font-size: 15px !important;
}
.modul-rest-totalHours {
  font-size: 15px !important;
  margin-top: 29px;
  margin-left: 10px;
  font-size: 13px;
}
.disabled-switcher {
  cursor: not-allowed !important;
}
.v-picker {
  border: 1px solid #4fb6e3;
}
.v-select .v-select__selections input {
  display: none;
}
.v-list-item--link::before {
  background-color: white !important;
}
.v-list-item--link:hover {
  background-color: #c4edfd !important;
}
.container-mobile {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.select-responsive .v-input__slot {
  cursor: default !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.select-big .v-input__slot {
  cursor: default !important;
  padding-left: 0px !important;
}
.chip__content {
  font-size: 5px !important;
}
.container-md-size {
  width: 94% !important;
}
.working-hours {
  font-size: 14px;
}
div.v-date-picker-table__events > div {
  width: 9px !important;
  height: 9px !important;
}
</style>
