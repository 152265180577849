<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <!-- <v-card-title
          class="white--text text-h5 lighten-2"
          style="background-color: #4fb6e3"
        >
          <v-icon color="primary lighten-1"> mdi-information </v-icon>
        </v-card-title> -->

        <v-card-text class="pt-7">
          Beim Ändern des Jahres, des Monats, der Arbeitsstunden, der
          Ausbildungsdauer, der gespeicherten Pläne, des Versorgungsbereichs
          sowie der Klasse, müssen alle geplanten Stunden zurückgesetzt werden,
          um Konflikte zu vermeiden.<br />

          Möchten Sie fortfahren?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#4FB6E3" text @click="$emit('close', true)"> Ja </v-btn>
          <v-btn color="error" text @click="$emit('close', false)">
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
