import Vue from "vue";

export default {
  getThreeYearsNotyMessage(modul) {
    switch (modul.name) {
      case "Orientierungseinsatz beim TpA":
      case "Pflichteinsatz Stationäre Akutpflege":
      case "Pflichteinsatz Ambulante Akut-/Langzeitpflege":
      case "Pflichteinsatz Stationäre Langzeitpflege":
        Vue.noty.warning(
          "Dieser Praxiseinsatz muss bis Ende des 2. Ausbildungsjahres abschlossen sein."
        );
        break;
      case "Pflichteinsatz Pädiatrische Versorgung":
        Vue.noty.warning(
          "Dieser Praxiseinsatz muss bis Ende des 2. Ausbildungsjahres abschlossen sein; Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen."
        );
        break;
      case "Pflichteinsatz Psychiatrische Versorgung":
        Vue.noty.warning(
          "Dieser Praxiseinsatz kann erst im 3. Ausbildungsdrittel geplant werden; Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen."
        );
        break;
      case "Vertiefungseinsatz im Bereich eines Pflichteinsatzes beim TpA":
      case "Wahleinsatz 1":
      case "Wahleinsatz 2":
        Vue.noty.warning(
          "Dieser Praxiseinsatz kann erst im 3. Ausbildungsdrittel geplant werden."
        );
        break;
      case "Urlaub":
        Vue.noty.warning("30 Urlaubstage pro Kalenderjahr.");
        break;
      default:
        "";
        break;
    }
  },

  getTwoYearsNotyMessage(modul) {
    switch (modul.name) {
      case "Pflichteinsatz Stationäre Akutpflege":
      case "Pflichteinsatz Stationäre Langzeitpflege":
      case "Pflichteinsatz Ambulante Akut-/Langzeitpflege":
        Vue.noty.warning(
          "Dieser Praxiseinsatz muss vor Ende des 1. Ausbildungsjahres abschlossen sein."
        );
        break;
      case "Pflichteinsatz Pädiatrische Versorgung":
        Vue.noty.warning(
          "Dieser Praxiseinsatz muss bis Ende des 1. Ausbildungsjahres abschlossen sein. Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen."
        );
        break;
      case "Pflichteinsatz Psychiatrische Versorgung":
        Vue.noty.warning(
          "Dieser Praxiseinsatz kann erst im 2. Ausbildungsjahr geplant werden. Um die Prxisbegleitung sicherzustellen, bitte diesen Einsatz möglichst außerhalb der Schulferien und an einem Stück planen."
        );
        break;
      case "Vertiefungseinsatz im Bereich eines Pflichteinsatzes":
      case "Wahleinsatz 1":
      case "Wahleinsatz 2":
        Vue.noty.warning(
          "Dieser Praxiseinsatz kann erst im 2. Ausbildungsjahr geplant werden."
        );
        break;
      case "Urlaub":
        Vue.noty.warning("30 Urlaubstage pro Kalenderjahr.");
        break;
      default:
        "";
        break;
    }
  },
};
