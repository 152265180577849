<template>
  <v-app id="app">
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
    <AboutDialog v-if="isAboutDialog" @close="isAboutDialog = false" />
  </v-app>
</template>

<script>
import Navbar from "@/components/shared/Navbar";
import Footer from "@/components/shared/Footer";
import AboutDialog from "@/components/shared/AboutDialog";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    AboutDialog,
  },

  data: () => ({
    isAboutDialog: false,
    isTrainingLengthDialog: false,
  }),
  created() {
    // TODO  Remove in the next release
    // Temporaray (Remove in the next release) ==>
    if (
      this.$route.path === "/drei-jahre" ||
      this.$route.path === "/zwei-jahre"
    ) {
      this.$router.push("/");
    }
    if (localStorage.getItem("disable-past-days") !== null) {
      localStorage.removeItem("disable-past-days");
    }
    this.checkLocalStorageFromLastReleases();
    // Temporaray (Remove in the next release) <==

    // TODO  Remove in the next release
    // Temporaray (Remove in the next release) ==>
    if (localStorage.getItem("app-version") === null) {
      localStorage.clear();
      localStorage.setItem("app-version", 6);
    }
    this.checkLocalStorageFromLastReleases();
    // Temporaray (Remove in the next release) <==

    if (sessionStorage.getItem("is-about") === null) {
      this.isAboutDialog = true;
      sessionStorage.setItem("is-about", true);
    }
  },
  methods: {
    checkLocalStorageFromLastReleases() {
      if (localStorage.getItem("burg-strasse-schule-3") !== null) {
        const oldSavedPlan = JSON.parse(
          localStorage.getItem("burg-strasse-schule-3")
        );
        localStorage.setItem(
          `${oldSavedPlan.student.firstname} ${oldSavedPlan.student.lastname}`,
          JSON.stringify(oldSavedPlan)
        );
        localStorage.removeItem("burg-strasse-schule-3");
      }
      if (localStorage.getItem("burg-strasse-schule-2") !== null) {
        const oldSavedPlan = JSON.parse(
          localStorage.getItem("burg-strasse-schule-2")
        );
        localStorage.setItem(
          `${oldSavedPlan.student.firstname} ${oldSavedPlan.student.lastname}`,
          JSON.stringify(oldSavedPlan)
        );
        localStorage.removeItem("burg-strasse-schule-2");
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Oswald-Light";
  src: local("Oswald-Light.ttf"),
    url("./assets/Fonts/Oswald-Light.ttf") format("truetype");
}
h1,
h2,
h5 {
  font-family: Oswald-Light;
  color: rgb(79, 182, 227);
}
h1 {
  font-size: clamp(1rem, 7vw, 2rem);
}
html {
  scroll-behavior: smooth;
}
</style>
