export default {
  data() {
    return {
      RangeContainsVacation: {},
    };
  },
  methods: {
    checkIfModulesIncludeVacation(vacationRange) {
      let foundModuleRange = null;
      let foundNightShiftModuleRange = null;
      let i = 0;

      while (i < this.modules.length) {
        foundModuleRange = this.modules[i].dayShiftRanges.find(
          (block) =>
            vacationRange.range[0] >= block.start &&
            vacationRange.range[1] <= block.end
        );

        foundNightShiftModuleRange = this.modules[i].nightShiftRanges.find(
          (block) =>
            vacationRange.range[0] >= block.start &&
            vacationRange.range[1] <= block.end
        );

        if (foundModuleRange != null && this.modules[i].name !== "Urlaub") {
          this.RangeContainsVacation = this.modules[i];
          this.splitFoundModuleRange(vacationRange, foundModuleRange);
          break;
        }

        if (foundNightShiftModuleRange != null) {
          this.RangeContainsVacation = this.modules[i];
          this.splitFoundNightShiftModuleRange(
            vacationRange,
            foundNightShiftModuleRange
          );
          break;
        }
        i++;
      }
    },

    splitFoundModuleRange(vacationRange, foundModuleRange) {
      let vacationStartDate = new Date(vacationRange.range[0]);

      let lastPlannedDateBeforVacationStarts = vacationStartDate;

      lastPlannedDateBeforVacationStarts.setDate(
        lastPlannedDateBeforVacationStarts.getDate() - 1
      );

      let vacationEndDate = new Date(vacationRange.range[1]);

      let firstPlannedDateAfterVacation = vacationEndDate;

      firstPlannedDateAfterVacation.setDate(
        firstPlannedDateAfterVacation.getDate() + 1
      );

      let trainingModuleStratDate = new Date(foundModuleRange.start);
      let trainingModuleEndDate = new Date(foundModuleRange.end);

      while (lastPlannedDateBeforVacationStarts >= trainingModuleStratDate) {
        if (
          !this.getFeasts.includes(
            lastPlannedDateBeforVacationStarts.toISOString().substr(0, 10)
          ) &&
          lastPlannedDateBeforVacationStarts.getDay() !== 6 &&
          lastPlannedDateBeforVacationStarts.getDay() !== 0
        ) {
          break;
        }
        lastPlannedDateBeforVacationStarts.setDate(
          lastPlannedDateBeforVacationStarts.getDate() - 1
        );
      }

      while (firstPlannedDateAfterVacation <= trainingModuleEndDate) {
        if (
          !this.getFeasts.includes(
            firstPlannedDateAfterVacation.toISOString().substr(0, 10)
          ) &&
          firstPlannedDateAfterVacation.getDay() !== 6 &&
          firstPlannedDateAfterVacation.getDay() !== 0
        ) {
          break;
        }
        firstPlannedDateAfterVacation.setDate(
          firstPlannedDateAfterVacation.getDate() + 1
        );
      }

      lastPlannedDateBeforVacationStarts = lastPlannedDateBeforVacationStarts
        .toISOString()
        .slice(0, 10);

      firstPlannedDateAfterVacation = firstPlannedDateAfterVacation
        .toISOString()
        .slice(0, 10);

      let rangeHours = this.getRangeHours(
        foundModuleRange.start,
        lastPlannedDateBeforVacationStarts
      );

      this.RangeContainsVacation.dayShiftRanges.push({
        start: foundModuleRange.start,
        end: lastPlannedDateBeforVacationStarts,
        plannedRangeHours: rangeHours,
      });
      this.RangeContainsVacation.plannedHours += rangeHours;

      rangeHours = this.getRangeHours(
        firstPlannedDateAfterVacation,
        foundModuleRange.end
      );

      this.RangeContainsVacation.dayShiftRanges.push({
        start: firstPlannedDateAfterVacation,
        end: foundModuleRange.end,
        plannedRangeHours: rangeHours,
      });
      this.RangeContainsVacation.plannedHours += rangeHours;

      this.RangeContainsVacation.plannedHours -=
        foundModuleRange.plannedRangeHours;

      let indexToDelete =
        this.RangeContainsVacation.dayShiftRanges.indexOf(foundModuleRange);
      this.RangeContainsVacation.dayShiftRanges.splice(indexToDelete, 1);

      if (
        this.RangeContainsVacation.plannedHours % 1 != 0 &&
        this.RangeContainsVacation.plannedHours.toString().split(".")[1]
          .length > 2
      ) {
        this.RangeContainsVacation.plannedHours = parseFloat(
          this.RangeContainsVacation.plannedHours
        ).toFixed(2);

        this.RangeContainsVacation.plannedHours = parseFloat(
          this.RangeContainsVacation.plannedHours
        );
      }
      this.updateStateModule(this.RangeContainsVacation);
    },

    splitFoundNightShiftModuleRange(vacationRange, foundModuleRange) {
      let vacationStartDate = new Date(vacationRange.range[0]);

      let lastPlannedDateBeforVacationStarts = vacationStartDate;

      lastPlannedDateBeforVacationStarts.setDate(
        lastPlannedDateBeforVacationStarts.getDate() - 1
      );

      lastPlannedDateBeforVacationStarts = lastPlannedDateBeforVacationStarts
        .toISOString()
        .slice(0, 10);

      let vacationEndDate = new Date(vacationRange.range[1]);

      let firstPlannedDateAfterVacation = vacationEndDate;

      firstPlannedDateAfterVacation.setDate(
        firstPlannedDateAfterVacation.getDate() + 1
      );

      firstPlannedDateAfterVacation = firstPlannedDateAfterVacation
        .toISOString()
        .slice(0, 10);

      let rangeHours = this.getRangeHours(
        foundModuleRange.start,
        lastPlannedDateBeforVacationStarts
      );

      this.RangeContainsVacation.nightShiftRanges.push({
        start: foundModuleRange.start,
        end: lastPlannedDateBeforVacationStarts,
        plannedRangeHours: rangeHours,
      });
      this.RangeContainsVacation.plannedHours += rangeHours;

      rangeHours = this.getRangeHours(
        firstPlannedDateAfterVacation,
        foundModuleRange.end
      );

      this.RangeContainsVacation.nightShiftRanges.push({
        start: firstPlannedDateAfterVacation,
        end: foundModuleRange.end,
        plannedRangeHours: rangeHours,
      });
      this.RangeContainsVacation.plannedHours += rangeHours;

      this.RangeContainsVacation.plannedHours -=
        foundModuleRange.plannedRangeHours;

      let indexToDelete =
        this.RangeContainsVacation.nightShiftRanges.indexOf(foundModuleRange);
      this.RangeContainsVacation.nightShiftRanges.splice(indexToDelete, 1);

      this.updateStateModule(this.RangeContainsVacation);
    },

    getRangeHours(start, end) {
      let hours = 0;
      const startRange = new Date(start);
      const endRange = new Date(end);
      let formattedStartDate = null;

      if (startRange.getHours() !== endRange.getHours()) {
        startRange.setHours(1);
        endRange.setHours(1);
      }

      const feastsSet = new Set(this.getFeasts);

      while (startRange <= endRange) {
        formattedStartDate = new Date(
          startRange.getTime() - startRange.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];

        if (
          !(startRange.getDay() === 6 || startRange.getDay() === 0) &&
          !feastsSet.has(formattedStartDate)
        ) {
          hours += this.workingHours;
        }

        startRange.setDate(startRange.getDate() + 1);
      }

      return hours;
    },
  },
};
