var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"company-form"}},[_c('v-form',{ref:"companyForm"},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Name des Betriebs ")]),_c('v-text-field',{attrs:{"rules":_vm.companyNameRules,"outlined":"","disabled":_vm.isDisabled,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.name),callback:function ($$v) {_vm.$set(_vm.companyData, "name", $$v)},expression:"companyData.name"}})],1)],1),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12","lg":"4"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Anrede ")]),_c('v-select',{attrs:{"items":_vm.genderItems,"item-text":"label","item-value":"value","outlined":"","disabled":_vm.isDisabled,"dense":"","rules":[function (v) { return !!v || 'Anrede ist erforderlich'; }]},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.contactPersonGender),callback:function ($$v) {_vm.$set(_vm.companyData, "contactPersonGender", $$v)},expression:"companyData.contactPersonGender"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"12","lg":"8"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Ansprechpartner:in ")]),_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"outlined":"","rules":_vm.contactPersonRules,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.contactPerson),callback:function ($$v) {_vm.$set(_vm.companyData, "contactPerson", $$v)},expression:"companyData.contactPerson"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"12","lg":"8"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Straße ")]),_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"outlined":"","rules":_vm.streetRules,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.street),callback:function ($$v) {_vm.$set(_vm.companyData, "street", $$v)},expression:"companyData.street"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12","lg":"4"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Hausnummer ")]),_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"outlined":"","rules":_vm.houseNumberRules,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.houseNumber),callback:function ($$v) {_vm.$set(_vm.companyData, "houseNumber", $$v)},expression:"companyData.houseNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"6"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Postleitzahl ")]),_c('v-text-field',{attrs:{"outlined":"","disabled":_vm.isDisabled,"rules":_vm.PostalCodeRules,"dense":""},on:{"input":_vm.getPostCodeValue,"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.postCode),callback:function ($$v) {_vm.postCode=$$v},expression:"postCode"}})],1),(_vm.isOneOrt)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"6"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Stadtteil ")]),_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"outlined":"","rules":_vm.placeRules,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.place),callback:function ($$v) {_vm.$set(_vm.companyData, "place", $$v)},expression:"companyData.place"}})],1):_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"6"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Stadtteil ")]),_c('v-select',{attrs:{"disabled":_vm.isDisabled,"items":_vm.orts,"item-text":"name","item-value":"value","outlined":"","dense":"","rules":[function (v) { return !!v || 'Stadtteil ist erforderlich'; }]},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.place),callback:function ($$v) {_vm.$set(_vm.companyData, "place", $$v)},expression:"companyData.place"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5","lg":"6"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * Telefonnummer (ohne Leerzeichen) ")]),_c('v-text-field',{attrs:{"outlined":"","disabled":_vm.isDisabled,"rules":_vm.telephonRules,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.telephone),callback:function ($$v) {_vm.$set(_vm.companyData, "telephone", $$v)},expression:"companyData.telephone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"6"}},[_c('div',{class:[
            _vm.isDisabled ? 'grey--text' : '',
            'd-flex flex-column w-100' ]},[_vm._v(" * E-Mail ")]),_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"outlined":"","rules":_vm.emailRules,"dense":""},on:{"blur":function($event){return _vm.$emit('company-data', _vm.companyData)}},model:{value:(_vm.companyData.email),callback:function ($$v) {_vm.$set(_vm.companyData, "email", $$v)},expression:"companyData.email"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }