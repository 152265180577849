<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title
          class="white--text text-h5 lighten-2"
          style="background-color: #4fb6e3"
        >
          Seite Zurücksetzen
        </v-card-title>

        <v-card-text v-if="isSavedPlan" class="mt-4">
          Möchten Sie den gespeicherten Plan für
          {{ getStudentData.firstname }} {{ getStudentData.lastname }} löschen?
          <br />
          Das kann nicht rückgängig gemacht werden
        </v-card-text>
        <v-card-text v-else class="mt-4">
          Möchten Sie alle Eingabe-Felder zurücksetzen?
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#4FB6E3" text @click="$emit('delete-plan')"> Ja </v-btn>
          <v-btn color="error" text @click="$emit('close-dialog')">
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: true,
      isSavedPlan: false,
    };
  },

  computed: {
    ...mapGetters("TrainingModules", {
      getStudentData: "getStudentData",
      getSavedPlansNames: "getSavedPlansNames",
    }),
  },

  created() {
    this.isSavedPlan = this.getSavedPlansNames.find(
      (savedPlan) =>
        savedPlan.name ===
        `${this.getStudentData.firstname} ${this.getStudentData.lastname}`
    )
      ? true
      : false;
  },
};
</script>
