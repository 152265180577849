<template>
  <div id="company-form">
    <v-form ref="companyForm">
      <v-row class="mt-4">
        <v-col cols="12" sm="12" md="12" lg="12">
          <!-- <label class="d-flex flex-column w-100" /> -->
          <!-- * Name des Betriebs -->
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Name des Betriebs
          </div>
          <v-text-field
            :rules="companyNameRules"
            v-model="companyData.name"
            outlined
            :disabled="isDisabled"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
      </v-row>
      <v-row justify="space-between" class="mt-0">
        <v-col cols="12" sm="4" md="12" lg="4">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Anrede
          </div>
          <v-select
            :items="genderItems"
            item-text="label"
            item-value="value"
            outlined
            :disabled="isDisabled"
            dense
            :rules="[(v) => !!v || 'Anrede ist erforderlich']"
            v-model="companyData.contactPersonGender"
            @blur="$emit('company-data', companyData)"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8" md="12" lg="8">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Ansprechpartner:in
          </div>
          <v-text-field
            v-model="companyData.contactPerson"
            :disabled="isDisabled"
            outlined
            :rules="contactPersonRules"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="8" md="12" lg="8">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Straße
          </div>
          <v-text-field
            v-model="companyData.street"
            :disabled="isDisabled"
            outlined
            :rules="streetRules"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
        <v-col cols="12" sm="4" md="12" lg="4">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Hausnummer
          </div>
          <v-text-field
            v-model="companyData.houseNumber"
            :disabled="isDisabled"
            outlined
            :rules="houseNumberRules"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="12" lg="6">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Postleitzahl
          </div>
          <v-text-field
            v-model="postCode"
            outlined
            :disabled="isDisabled"
            :rules="PostalCodeRules"
            @input="getPostCodeValue"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
        <v-col v-if="isOneOrt" cols="12" sm="6" md="12" lg="6">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Stadtteil
          </div>
          <v-text-field
            v-model="companyData.place"
            :disabled="isDisabled"
            outlined
            :rules="placeRules"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
        <v-col v-else cols="12" sm="6" md="12" lg="6">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Stadtteil
          </div>
          <v-select
            v-model="companyData.place"
            :disabled="isDisabled"
            :items="orts"
            item-text="name"
            item-value="value"
            outlined
            dense
            :rules="[(v) => !!v || 'Stadtteil ist erforderlich']"
            @blur="$emit('company-data', companyData)"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="5" lg="6">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * Telefonnummer (ohne Leerzeichen)
          </div>
          <v-text-field
            v-model="companyData.telephone"
            outlined
            :disabled="isDisabled"
            :rules="telephonRules"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
        <v-col cols="12" sm="12" md="7" lg="6">
          <div
            :class="[
              isDisabled ? 'grey--text' : '',
              'd-flex flex-column w-100',
            ]"
          >
            * E-Mail
          </div>
          <v-text-field
            v-model="companyData.email"
            :disabled="isDisabled"
            outlined
            :rules="emailRules"
            dense
            @blur="$emit('company-data', companyData)"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import screenSize from "@/helpers/screenSize";
import placesComputed from "@/helpers/places";
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    data: {
      typ: Object,
      default: null,
    },
    isDisabled: {
      typ: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {},
      companyData: {},
      genderItems: [
        { label: "Herr", value: "Herr" },
        { label: "Frau", value: "Frau" },
        { label: "Divers", value: "Divers" },
      ],
      isOneOrt: true,
      orts: [],
      postCode: "",
      // RULES
      specialChars: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
      companyNameRules: [
        (v) => !!v || "Name des Betriebs ist erforderlich",
        (v) => !/\d/.test(v) || "Kein gültiger Wert!",
      ],
      contactPersonRules: [
        (v) => !!v || "Ansprechpartner:in ist erforderlich",
        (v) => !/\d/.test(v) || "Kein gültiger Wert!",
      ],
      streetRules: [
        (v) => !!v || "Straße ist erforderlich",
        (v) => !/\d/.test(v) || "Kein gültiger Wert!",
      ],
      houseNumberRules: [(v) => !!v || "Hausnummer ist erforderlich"],

      PostalCodeRules: [
        (v) => !!v || "Postleitzahl ist erforderlich",
        (v) => !!/^\d+$/.test(v) || "Kein gültiger Wert!",
        (v) => v.length < 6 || "Kein gültiger Wert!",
        (v) => v.length >= 5 || "Kein gültiger Wert!",
      ],
      placeRules: [
        (v) => !!v || "Stadtteil ist erforderlich",
        (v) => !/\d/.test(v) || "Kein gültiger Wert!",
      ],
      telephonRules: [
        (v) => !!v || "Telefonnummer ist erforderlich",
        (v) => !!/^\d+$/.test(v) || "Kein gültiger Wert!",
      ],
      emailRules: [
        (v) => !!v || "E-Mail ist erforderlich",
        (v) => /.+@.+\..+/.test(v) || "Kein gültiger Wert!",
      ],
    };
  },
  computed: {
    ...screenSize,
    ...placesComputed,
    ...mapGetters("App", { getFoundDistrict: "getFoundDistrict" }),
  },
  watch: {},
  created() {
    if (this.data !== undefined) {
      this.companyData = this.data;
      this.postCode = this.companyData.postCode;
      if (this.companyData.place !== "" && this.companyData.place !== undefined)
        return;
      this.findDistrict();
    }
  },
  methods: {
    getPostCodeValue(val) {
      this.companyData.postCode = val;
      this.findDistrict();
    },
    async findDistrict() {
      this.orts = [];
      const foundOrts = placesComputed.getFoundDistrict(
        this.companyData.postCode
      );
      if (foundOrts.length === 0) {
        this.isOneOrt = true;
        this.companyData.place = "";
        return;
      }
      if (foundOrts.length > 1) {
        this.isOneOrt = false;
        foundOrts.forEach((ort) => {
          this.orts.push({ name: ort.district, value: ort.district });
        });
      } else {
        this.isOneOrt = true;
        this.companyData.place = await placesComputed.getFoundDistrict(
          this.companyData.postCode
        )[0].district;
      }
    },

    validateForm() {
      this.$emit("company-data", this.companyData);
      return this.$refs.companyForm.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-top: 0px !important;
}
</style>
