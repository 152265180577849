<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-text class="pt-7">
          Es existiert bereits ein gespeicherter Plan für diesen Auszubildenden
          mit einer anderen Ausbildungsdauer. Beim Speicheren dieses Plan wird
          der gespeicherte Plan überschrieben.
          <br />
          Möchten Sie den gespeicherten Plan mit dem aktuellen Plan ersetzen?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#4FB6E3" text @click="$emit('close', 'ja')"> Ja </v-btn>
          <v-btn color="error" text @click="$emit('close', 'nein')">
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
